/* The following is the default reset styles from Tailwind. It would usually be
included automatically by Tailwind but there are styles in here that conflict
with ours and we can't force this to be loaded first so that our styles override
them. We can remove the styles below that conflict with ours below. */

/*
1. Prevent padding and border from affecting element width. (https://github.com/mozdevs/cssremedy/issues/4)
2. Allow adding a border to an element by just adding a border-width. (https://github.com/tailwindcss/tailwindcss/pull/116)
*/

*,
::before,
::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* 1 */
  border-width: 0;
  /* 2 */
  border-style: solid;
  /* 2 */
  border-color: #EEF0F5;
  /* 2 */
}

::before,
::after {
  --tw-content: '';
}

/*
1. Use a consistent sensible line-height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
3. Use a more readable tab size.
4. Use the user's configured `sans` font-family by default.
*/

html {
  line-height: 1.5;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -moz-tab-size: 4;
  /* 3 */
  -o-tab-size: 4;
     tab-size: 4;
  /* 3 */
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  /* 4 */
}

/*
1. Remove the margin in all browsers.
2. Inherit line-height from `html` so users can set them as a class directly on the `html` element.
*/

body {
  margin: 0;
  /* 1 */
  line-height: inherit;
  /* 2 */
}

/*
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
3. Ensure horizontal rules are visible by default.
*/

hr {
  height: 0;
  /* 1 */
  color: inherit;
  /* 2 */
  border-top-width: 1px;
  /* 3 */
}

/*
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/*
Remove the default font size and weight for headings.
*/

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/*
Reset links to optimize for opt-in styling instead of opt-out.
*/

a {
  color: inherit;
  text-decoration: inherit;
}

/*
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/*
1. Use the user's configured `mono` font family by default.
2. Correct the odd `em` font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/*
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/*
Prevent `sub` and `sup` elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
3. Remove gaps between table borders by default.
*/

table {
  text-indent: 0;
  /* 1 */
  border-color: inherit;
  /* 2 */
  border-collapse: collapse;
  /* 3 */
}

/*
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
3. Remove default padding in all browsers.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: inherit;
  /* 1 */
  color: inherit;
  /* 1 */
  margin: 0;
  /* 2 */
  padding: 0;
  /* 3 */
}

/*
Remove the inheritance of text transform in Edge and Firefox.
*/

button,
select {
  text-transform: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Remove default button styles.
*/

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  /* 1 */
  /* Removing this because it overrides the bg classes on our buttons */
  /* background-color: transparent; */
  background-image: none;
  /* 2 */
}

/*
Use the modern Firefox focus style for all focusable elements.
*/

:-moz-focusring {
  outline: auto;
}

/*
Remove the additional `:invalid` styles in Firefox. (https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737)
*/

:-moz-ui-invalid {
  box-shadow: none;
}

/*
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/*
Correct the cursor style of increment and decrement buttons in Safari.
*/

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/*
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

[type='search'] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/*
Remove the inner padding in Chrome and Safari on macOS.
*/

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/*
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to `inherit` in Safari.
*/

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/*
Removes the default spacing and border for appropriate elements.
*/

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

/*
Prevent resizing textareas horizontally by default.
*/

textarea {
  resize: vertical;
}

/*
1. Reset the default placeholder opacity in Firefox. (https://github.com/tailwindlabs/tailwindcss/issues/3300)
2. Set the default placeholder color to the user's configured gray 400 color.
*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  opacity: 1;
  /* 1 */
  /* Deleting this because it differs from the v2 preflight styles */
  /* color: theme('colors.gray.400', #9ca3af); */
  color: #A0AEC0;
  /* 2 */
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  opacity: 1;
  /* 1 */
  /* Deleting this because it differs from the v2 preflight styles */
  /* color: theme('colors.gray.400', #9ca3af); */
  color: #A0AEC0;
  /* 2 */
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  opacity: 1;
  /* 1 */
  /* Deleting this because it differs from the v2 preflight styles */
  /* color: theme('colors.gray.400', #9ca3af); */
  color: #A0AEC0;
  /* 2 */
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  /* 1 */
  /* Deleting this because it differs from the v2 preflight styles */
  /* color: theme('colors.gray.400', #9ca3af); */
  color: #A0AEC0;
  /* 2 */
}

/*
Set the default cursor for buttons.
*/

button,
[role='button'] {
  cursor: pointer;
}

/*
Make sure disabled buttons don't get the pointer cursor.
*/

:disabled {
  cursor: default;
}

/*
1. Make replaced elements `display: block` by default. (https://github.com/mozdevs/cssremedy/issues/14)
2. Add `vertical-align: middle` to align replaced elements more sensibly by default. (https://github.com/jensimmons/cssremedy/issues/14#issuecomment-634934210)
   This can trigger a poorly considered lint error in some tools but is included by design.
*/

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  /* 1 */
  vertical-align: middle;
  /* 2 */
}

/*
Constrain images and videos to the parent width and preserve their intrinsic aspect ratio. (https://github.com/mozdevs/cssremedy/issues/14)
*/

img,
video {
  max-width: 100%;
  height: auto;
}

/*
Ensure the default browser behavior of the `hidden` attribute.
*/

[hidden] {
  display: none;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(65 147 241 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(65 147 241 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(65 147 241 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.lm-pointer-events-none {
  pointer-events: none;
}

.lm-invisible {
  visibility: hidden;
}

.lm-fixed {
  position: fixed;
}

.lm-absolute {
  position: absolute;
}

.lm-relative {
  position: relative;
}

.lm-sticky {
  position: sticky;
}

.lm-inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.lm--inset-6 {
  top: -1.5rem;
  right: -1.5rem;
  bottom: -1.5rem;
  left: -1.5rem;
}

.lm-inset-x-0 {
  left: 0px;
  right: 0px;
}

.lm-bottom-0 {
  bottom: 0px;
}

.lm-left-0 {
  left: 0px;
}

.lm-right-0 {
  right: 0px;
}

.lm-top-8 {
  top: 2rem;
}

.lm-top-5 {
  top: 1.25rem;
}

.lm-left-\[22px\] {
  left: 22px;
}

.lm-right-\[22px\] {
  right: 22px;
}

.lm-top-0 {
  top: 0px;
}

.lm-top-auto {
  top: auto;
}

.lm-bottom-\[-24px\] {
  bottom: -24px;
}

.lm-top-\[14px\] {
  top: 14px;
}

.lm-right-2 {
  right: 0.5rem;
}

.lm-bottom-4 {
  bottom: 1rem;
}

.lm-right-4 {
  right: 1rem;
}

.lm-left-10 {
  left: 2.5rem;
}

.lm-bottom-6 {
  bottom: 1.5rem;
}

.lm-right-6 {
  right: 1.5rem;
}

.lm-top-14 {
  top: 3.5rem;
}

.lm-bottom-36 {
  bottom: 9rem;
}

.lm-left-4 {
  left: 1rem;
}

.lm-bottom-16 {
  bottom: 4rem;
}

.lm-right-10 {
  right: 2.5rem;
}

.lm-top-2 {
  top: 0.5rem;
}

.lm-top-1\/2 {
  top: 50%;
}

.lm-left-1\/2 {
  left: 50%;
}

.lm-top-6 {
  top: 1.5rem;
}

.lm-top-\[32px\] {
  top: 32px;
}

.lm-bottom-5 {
  bottom: 1.25rem;
}

.lm--top-13 {
  top: -3.25rem;
}

.lm-top-\[-51px\] {
  top: -51px;
}

.lm-right-\[-31px\] {
  right: -31px;
}

.lm-top-\[-30px\] {
  top: -30px;
}

.lm-left-32 {
  left: 8rem;
}

.lm-bottom-2 {
  bottom: 0.5rem;
}

.lm-top-\[29px\] {
  top: 29px;
}

.lm-right-\[41px\] {
  right: 41px;
}

.lm-top-\[-25px\] {
  top: -25px;
}

.lm-left-half {
  left: 50%;
}

.lm-right-\[-115px\] {
  right: -115px;
}

.lm-top-\[61px\] {
  top: 61px;
}

.lm-z-20 {
  z-index: 20;
}

.lm-z-10 {
  z-index: 10;
}

.lm-z-30 {
  z-index: 30;
}

.lm-z-\[1100\] {
  z-index: 1100;
}

.lm-z-\[1\] {
  z-index: 1;
}

.lm-z-\[1101\] {
  z-index: 1101;
}

.lm-z-50 {
  z-index: 50;
}

.lm-z-0 {
  z-index: 0;
}

.lm-z-\[999\] {
  z-index: 999;
}

.lm-order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
}

.lm-order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1;
}

.lm-col-span-6 {
  grid-column: span 6 / span 6;
}

.lm-col-span-1 {
  grid-column: span 1 / span 1;
}

.lm-col-span-2 {
  grid-column: span 2 / span 2;
}

.lm-col-span-3 {
  grid-column: span 3 / span 3;
}

.lm-col-span-4 {
  grid-column: span 4 / span 4;
}

.lm-col-span-5 {
  grid-column: span 5 / span 5;
}

.lm-col-span-7 {
  grid-column: span 7 / span 7;
}

.lm-col-span-8 {
  grid-column: span 8 / span 8;
}

.lm-col-span-9 {
  grid-column: span 9 / span 9;
}

.lm-col-span-10 {
  grid-column: span 10 / span 10;
}

.lm-col-span-11 {
  grid-column: span 11 / span 11;
}

.lm-col-span-12 {
  grid-column: span 12 / span 12;
}

.lm-col-span-full {
  grid-column: 1 / -1;
}

.lm-col-start-1 {
  grid-column-start: 1;
}

.lm-col-start-2 {
  grid-column-start: 2;
}

.lm-col-start-3 {
  grid-column-start: 3;
}

.lm-col-start-4 {
  grid-column-start: 4;
}

.lm-row-span-2 {
  grid-row: span 2 / span 2;
}

.lm-row-span-1 {
  grid-row: span 1 / span 1;
}

.lm-row-start-1 {
  grid-row-start: 1;
}

.lm-float-right {
  float: right;
}

.lm-float-left {
  float: left;
}

.lm-m-0 {
  margin: 0px;
}

.lm-m-auto {
  margin: auto;
}

.lm-m-1 {
  margin: 0.25rem;
}

.lm-m-2 {
  margin: 0.5rem;
}

.lm-m-3 {
  margin: 0.75rem;
}

.lm-m-4 {
  margin: 1rem;
}

.lm-m-5 {
  margin: 1.25rem;
}

.lm-m-6 {
  margin: 1.5rem;
}

.lm-m-8 {
  margin: 2rem;
}

.lm-m-10 {
  margin: 2.5rem;
}

.lm--m-8 {
  margin: -2rem;
}

.lm-m-\[-0\.5rem\] {
  margin: -0.5rem;
}

.lm-m-12 {
  margin: 3rem;
}

.lm-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.lm-my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.lm-my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.lm-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.lm-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.lm-mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.lm-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.lm-mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.lm-mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.lm-mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.lm-mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.lm-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.lm-mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.lm-mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.lm-mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem;
}

.lm-my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.lm-my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.lm-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.lm-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.lm-my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.lm-mx-0\.5 {
  margin-left: 0.125rem;
  margin-right: 0.125rem;
}

.lm-mx-\[-39px\] {
  margin-left: -39px;
  margin-right: -39px;
}

.lm-my-\[14px\] {
  margin-top: 14px;
  margin-bottom: 14px;
}

.lm-my-25 {
  margin-top: 6.25rem;
  margin-bottom: 6.25rem;
}

.lm-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.lm-my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.lm-my-\[30px\] {
  margin-top: 30px;
  margin-bottom: 30px;
}

.lm--mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.lm-my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.lm-ml-2 {
  margin-left: 0.5rem;
}

.lm-mt-4 {
  margin-top: 1rem;
}

.lm-mr-8 {
  margin-right: 2rem;
}

.lm-mt-2 {
  margin-top: 0.5rem;
}

.lm-mr-2 {
  margin-right: 0.5rem;
}

.lm-mb-2 {
  margin-bottom: 0.5rem;
}

.lm-mt-1 {
  margin-top: 0.25rem;
}

.lm-ml-1 {
  margin-left: 0.25rem;
}

.lm-mb-8 {
  margin-bottom: 2rem;
}

.lm-mt-8 {
  margin-top: 2rem;
}

.lm-mb-4 {
  margin-bottom: 1rem;
}

.lm-mt-12 {
  margin-top: 3rem;
}

.lm-mb-10 {
  margin-bottom: 2.5rem;
}

.lm-mr-3 {
  margin-right: 0.75rem;
}

.lm-mr-1 {
  margin-right: 0.25rem;
}

.lm-mb-6 {
  margin-bottom: 1.5rem;
}

.lm-ml-auto {
  margin-left: auto;
}

.lm-mr-auto {
  margin-right: auto;
}

.lm-mb-12 {
  margin-bottom: 3rem;
}

.lm-mt-16 {
  margin-top: 4rem;
}

.lm-ml-6 {
  margin-left: 1.5rem;
}

.lm-mb-0 {
  margin-bottom: 0px;
}

.lm-mr-4 {
  margin-right: 1rem;
}

.lm-mb-3 {
  margin-bottom: 0.75rem;
}

.lm-mb-15 {
  margin-bottom: 3.75rem;
}

.lm--mt-4 {
  margin-top: -1rem;
}

.lm-ml-4 {
  margin-left: 1rem;
}

.lm-ml-0 {
  margin-left: 0px;
}

.lm-ml-3 {
  margin-left: 0.75rem;
}

.lm-ml-5 {
  margin-left: 1.25rem;
}

.lm-ml-8 {
  margin-left: 2rem;
}

.lm-ml-10 {
  margin-left: 2.5rem;
}

.lm-mr-0 {
  margin-right: 0px;
}

.lm-mr-5 {
  margin-right: 1.25rem;
}

.lm-mr-6 {
  margin-right: 1.5rem;
}

.lm-mr-10 {
  margin-right: 2.5rem;
}

.lm-mt-0 {
  margin-top: 0px;
}

.lm-mt-3 {
  margin-top: 0.75rem;
}

.lm-mt-5 {
  margin-top: 1.25rem;
}

.lm-mt-6 {
  margin-top: 1.5rem;
}

.lm-mt-10 {
  margin-top: 2.5rem;
}

.lm-mb-1 {
  margin-bottom: 0.25rem;
}

.lm-mb-5 {
  margin-bottom: 1.25rem;
}

.lm--mb-8 {
  margin-bottom: -2rem;
}

.lm-mt-7 {
  margin-top: 1.75rem;
}

.lm-mb-px {
  margin-bottom: 1px;
}

.lm-mt-px {
  margin-top: 1px;
}

.lm-mt-auto {
  margin-top: auto;
}

.lm-mb-auto {
  margin-bottom: auto;
}

.lm-mt-0\.5 {
  margin-top: 0.125rem;
}

.lm-mb-2\.5 {
  margin-bottom: 0.625rem;
}

.lm--ml-5 {
  margin-left: -1.25rem;
}

.lm--mb-1 {
  margin-bottom: -0.25rem;
}

.lm-mb-7 {
  margin-bottom: 1.75rem;
}

.lm-ml-\[22px\] {
  margin-left: 22px;
}

.lm--mt-\[6px\] {
  margin-top: -6px;
}

.lm-mb-0\.5 {
  margin-bottom: 0.125rem;
}

.lm-ml-\[215px\] {
  margin-left: 215px;
}

.lm--mt-\[48px\] {
  margin-top: -48px;
}

.lm-ml-\[64px\] {
  margin-left: 64px;
}

.lm-ml-\[200px\] {
  margin-left: 200px;
}

.lm--mt-\[16px\] {
  margin-top: -16px;
}

.lm-ml-\[50px\] {
  margin-left: 50px;
}

.lm-mb-16 {
  margin-bottom: 4rem;
}

.lm-ml-32 {
  margin-left: 8rem;
}

.lm-ml-1\/8 {
  margin-left: 12.5%;
}

.lm-mt-\[1px\] {
  margin-top: 1px;
}

.lm-mr-\[10px\] {
  margin-right: 10px;
}

.-lm-mt-1 {
  margin-top: -0.25rem;
}

.lm-mb-\[6px\] {
  margin-bottom: 6px;
}

.lm-mt-32 {
  margin-top: 8rem;
}

.lm-mt-20 {
  margin-top: 5rem;
}

.\!lm-mt-2 {
  margin-top: 0.5rem !important;
}

.lm-mb-3\.5 {
  margin-bottom: 0.875rem;
}

.lm-ml-7 {
  margin-left: 1.75rem;
}

.lm-mt-2\.5 {
  margin-top: 0.625rem;
}

.lm-mb-\[30px\] {
  margin-bottom: 30px;
}

.lm-ml-12 {
  margin-left: 3rem;
}

.lm-mt-\[30px\] {
  margin-top: 30px;
}

.lm-mt-\[2px\] {
  margin-top: 2px;
}

.lm-mr-2\.5 {
  margin-right: 0.625rem;
}

.lm-mt-\[-10px\] {
  margin-top: -10px;
}

.lm-mr-\[14px\] {
  margin-right: 14px;
}

.\!lm-mr-\[14px\] {
  margin-right: 14px !important;
}

.\!lm-mr-0 {
  margin-right: 0px !important;
}

.lm-mt-\[14px\] {
  margin-top: 14px;
}

.lm-mt-\[10px\] {
  margin-top: 10px;
}

.lm-mb-9 {
  margin-bottom: 2.25rem;
}

.lm-ml-\[10px\] {
  margin-left: 10px;
}

.lm-mr-15 {
  margin-right: 3.75rem;
}

.lm-mb-20 {
  margin-bottom: 5rem;
}

.lm-mb-11 {
  margin-bottom: 2.75rem;
}

.lm-mt-\[50px\] {
  margin-top: 50px;
}

.lm-mt-9 {
  margin-top: 2.25rem;
}

.lm-mt-40 {
  margin-top: 10rem;
}

.lm--mt-1 {
  margin-top: -0.25rem;
}

.lm-mr-3\.5 {
  margin-right: 0.875rem;
}

.lm-mr-7 {
  margin-right: 1.75rem;
}

.lm-ml-2\.5 {
  margin-left: 0.625rem;
}

.lm-ml-\[14px\] {
  margin-left: 14px;
}

.lm-mb-\[10px\] {
  margin-bottom: 10px;
}

.lm-ml-48 {
  margin-left: 12rem;
}

.lm--mt-6 {
  margin-top: -1.5rem;
}

.lm-ml-\[-20px\] {
  margin-left: -20px;
}

.lm-mt-25 {
  margin-top: 6.25rem;
}

.lm-mt-\[3px\] {
  margin-top: 3px;
}

.lm-mt-15 {
  margin-top: 3.75rem;
}

.lm-mt-3\.5 {
  margin-top: 0.875rem;
}

.lm-mt-1\.5 {
  margin-top: 0.375rem;
}

.lm--mb-3 {
  margin-bottom: -0.75rem;
}

.lm--ml-2 {
  margin-left: -0.5rem;
}

.lm--mt-2 {
  margin-top: -0.5rem;
}

.lm--mr-2 {
  margin-right: -0.5rem;
}

.lm-box-border {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.lm-box-content {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
}

.lm-block {
  display: block;
}

.lm-inline-block {
  display: inline-block;
}

.lm-inline {
  display: inline;
}

.lm-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.lm-inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.lm-table {
  display: table;
}

.lm-inline-table {
  display: inline-table;
}

.lm-table-cell {
  display: table-cell;
}

.lm-grid {
  display: grid;
}

.lm-hidden {
  display: none;
}

.lm-h-full {
  height: 100%;
}

.lm-h-6 {
  height: 1.5rem;
}

.lm-h-4 {
  height: 1rem;
}

.lm-h-10 {
  height: 2.5rem;
}

.lm-h-20 {
  height: 5rem;
}

.lm-h-5 {
  height: 1.25rem;
}

.lm-h-8 {
  height: 2rem;
}

.lm-h-48 {
  height: 12rem;
}

.lm-h-15 {
  height: 3.75rem;
}

.lm-h-3 {
  height: 0.75rem;
}

.lm-h-px {
  height: 1px;
}

.lm-h-header-nav-mobile {
  height: 70px;
}

.lm-h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.lm-h-auto {
  height: auto;
}

.lm-h-12 {
  height: 3rem;
}

.lm-h-36 {
  height: 9rem;
}

.lm-h-9 {
  height: 2.25rem;
}

.lm-h-16 {
  height: 4rem;
}

.lm-h-0\.5 {
  height: 0.125rem;
}

.lm-h-0 {
  height: 0px;
}

.lm-h-1 {
  height: 0.25rem;
}

.lm-h-\[9px\] {
  height: 9px;
}

.lm-h-28 {
  height: 7rem;
}

.lm-h-14 {
  height: 3.5rem;
}

.lm-h-screen {
  height: 100vh;
}

.lm-h-\[8px\] {
  height: 8px;
}

.lm-h-\[12px\] {
  height: 12px;
}

.lm-h-40 {
  height: 10rem;
}

.lm-h-72 {
  height: 18rem;
}

.lm-h-7 {
  height: 1.75rem;
}

.lm-h-\[70px\] {
  height: 70px;
}

.lm-h-11 {
  height: 2.75rem;
}

.lm-h-118 {
  height: 30rem;
}

.lm-h-\[526px\] {
  height: 526px;
}

.lm-h-\[18px\] {
  height: 18px;
}

.lm-h-26 {
  height: 6.5rem;
}

.lm-h-144 {
  height: 36rem;
}

.lm-h-\[47px\] {
  height: 47px;
}

.lm-h-180 {
  height: 45rem;
}

.lm-h-\[38px\] {
  height: 38px;
}

.lm-h-\[100px\] {
  height: 100px;
}

.lm-h-52 {
  height: 13rem;
}

.lm-h-96 {
  height: 24rem;
}

.lm-h-\[80px\] {
  height: 80px;
}

.lm-h-\[14px\] {
  height: 14px;
}

.lm-h-24 {
  height: 6rem;
}

.lm-h-13 {
  height: 3.25rem;
}

.lm-h-\[42px\] {
  height: 42px;
}

.lm-h-64 {
  height: 16rem;
}

.lm-h-\[90vh\] {
  height: 90vh;
}

.lm-h-13\.5 {
  height: 3.375rem;
}

.lm-h-\[140px\] {
  height: 140px;
}

.lm-h-80 {
  height: 20rem;
}

.lm-h-\[2px\] {
  height: 2px;
}

.lm-h-\[44px\] {
  height: 44px;
}

.lm-h-\[21px\] {
  height: 21px;
}

.lm-h-\[1px\] {
  height: 1px;
}

.lm-h-1\/2 {
  height: 50%;
}

.lm-max-h-\[330px\] {
  max-height: 330px;
}

.lm-max-h-8 {
  max-height: 2rem;
}

.lm-max-h-\[800px\] {
  max-height: 800px;
}

.lm-max-h-\[82px\] {
  max-height: 82px;
}

.lm-max-h-\[480px\] {
  max-height: 480px;
}

.lm-max-h-screen {
  max-height: 100vh;
}

.lm-max-h-\[1000px\] {
  max-height: 1000px;
}

.lm-max-h-\[inherit\] {
  max-height: inherit;
}

.lm-max-h-80 {
  max-height: 20rem;
}

.lm-max-h-full {
  max-height: 100%;
}

.lm-max-h-110 {
  max-height: 28rem;
}

.lm-max-h-54 {
  max-height: 3.375rem;
}

.lm-min-h-\[120px\] {
  min-height: 120px;
}

.lm-min-h-full {
  min-height: 100%;
}

.lm-min-h-textarea {
  min-height: 8rem;
}

.lm-min-h-\[160px\] {
  min-height: 160px;
}

.lm-min-h-13\.5 {
  min-height: 3.375rem;
}

.lm-min-h-\[8px\] {
  min-height: 8px;
}

.lm-min-h-\[12px\] {
  min-height: 12px;
}

.lm-min-h-\[80px\] {
  min-height: 80px;
}

.lm-min-h-\[464px\] {
  min-height: 464px;
}

.lm-min-h-\[90px\] {
  min-height: 90px;
}

.lm-min-h-\[600px\] {
  min-height: 600px;
}

.lm-min-h-48 {
  min-height: 3rem;
}

.lm-w-full {
  width: 100%;
}

.lm-w-4 {
  width: 1rem;
}

.lm-w-card {
  width: 40rem;
}

.lm-w-6 {
  width: 1.5rem;
}

.lm-w-\[204px\] {
  width: 204px;
}

.\!lm-w-full {
  width: 100% !important;
}

.lm-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.lm-w-\[23\%\] {
  width: 23%;
}

.lm-w-102 {
  width: 26rem;
}

.lm-w-1\/2 {
  width: 50%;
}

.lm-w-3\/12 {
  width: 25%;
}

.lm-w-9\/12 {
  width: 75%;
}

.lm-w-11\/12 {
  width: 91.666667%;
}

.lm-w-5 {
  width: 1.25rem;
}

.lm-w-\[240px\] {
  width: 240px;
}

.lm-w-8 {
  width: 2rem;
}

.lm-w-12 {
  width: 3rem;
}

.lm-w-3 {
  width: 0.75rem;
}

.lm-w-26 {
  width: 6.5rem;
}

.lm-w-44 {
  width: 11rem;
}

.lm-w-10 {
  width: 2.5rem;
}

.lm-w-50 {
  width: 12.5rem;
}

.lm-w-\[840px\] {
  width: 840px;
}

.lm-w-56 {
  width: 14rem;
}

.lm-w-40 {
  width: 10rem;
}

.lm-w-32 {
  width: 8rem;
}

.lm-w-24 {
  width: 6rem;
}

.lm-w-max {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.lm-w-auto {
  width: auto;
}

.lm-w-118 {
  width: 30rem;
}

.lm-w-36 {
  width: 9rem;
}

.lm-w-0\.5 {
  width: 0.125rem;
}

.lm-w-0 {
  width: 0px;
}

.lm-w-px {
  width: 1px;
}

.lm-w-1\/4 {
  width: 25%;
}

.lm-w-88 {
  width: 22rem;
}

.lm-w-1 {
  width: 0.25rem;
}

.lm-w-\[9px\] {
  width: 9px;
}

.lm-w-52 {
  width: 13rem;
}

.lm-w-34 {
  width: 8.5rem;
}

.lm-w-screen {
  width: 100vw;
}

.lm-w-48 {
  width: 12rem;
}

.lm-w-\[8px\] {
  width: 8px;
}

.lm-w-\[12px\] {
  width: 12px;
}

.lm-w-3\/5 {
  width: 60%;
}

.lm-w-\[200px\] {
  width: 200px;
}

.lm-w-11 {
  width: 2.75rem;
}

.lm-w-\[916px\] {
  width: 916px;
}

.lm-w-72 {
  width: 18rem;
}

.lm-w-2\/5 {
  width: 40%;
}

.lm-w-4\/5 {
  width: 80%;
}

.lm-w-3\/4 {
  width: 75%;
}

.lm-w-20 {
  width: 5rem;
}

.lm-w-64 {
  width: 16rem;
}

.lm-w-\[18px\] {
  width: 18px;
}

.lm-w-\[236px\] {
  width: 236px;
}

.lm-w-\[306px\] {
  width: 306px;
}

.lm-w-96 {
  width: 24rem;
}

.lm-w-60 {
  width: 15rem;
}

.lm-w-54 {
  width: 13.5rem;
}

.lm-w-\[596px\] {
  width: 596px;
}

.lm-w-\[78px\] {
  width: 78px;
}

.lm-w-\[100px\] {
  width: 100px;
}

.lm-w-\[324px\] {
  width: 324px;
}

.lm-w-\[70px\] {
  width: 70px;
}

.lm-w-\[14px\] {
  width: 14px;
}

.lm-w-13 {
  width: 3.25rem;
}

.lm-w-\[42px\] {
  width: 42px;
}

.lm-w-\[1px\] {
  width: 1px;
}

.lm-w-\[750px\] {
  width: 750px;
}

.lm-w-\[250px\] {
  width: 250px;
}

.lm-w-\[304px\] {
  width: 304px;
}

.lm-w-\[84px\] {
  width: 84px;
}

.lm-w-\[72px\] {
  width: 72px;
}

.lm-w-\[600px\] {
  width: 600px;
}

.lm-w-\[300px\] {
  width: 300px;
}

.lm-w-\[70vw\] {
  width: 70vw;
}

.lm-w-\[653px\] {
  width: 653px;
}

.lm-w-\[633px\] {
  width: 633px;
}

.lm-w-\[90px\] {
  width: 90px;
}

.lm-w-1\/3 {
  width: 33.333333%;
}

.lm-w-14 {
  width: 3.5rem;
}

.lm-w-144 {
  width: 36rem;
}

.lm-w-\[768px\] {
  width: 768px;
}

.lm-w-110 {
  width: 28rem;
}

.lm-w-\[650px\] {
  width: 650px;
}

.lm-w-\[38rem\] {
  width: 38rem;
}

.lm-w-9 {
  width: 2.25rem;
}

.lm-w-\[44px\] {
  width: 44px;
}

.lm-w-80 {
  width: 20rem;
}

.lm-w-256 {
  width: 64rem;
}

.lm-w-\[40\%\] {
  width: 40%;
}

.lm-w-\[287px\] {
  width: 287px;
}

.lm-w-\[870px\] {
  width: 870px;
}

.lm-w-\[658px\] {
  width: 658px;
}

.lm-w-2\/3 {
  width: 66.666667%;
}

.lm-min-w-4 {
  min-width: 1rem;
}

.lm-min-w-\[175px\] {
  min-width: 175px;
}

.lm-min-w-8 {
  min-width: 2rem;
}

.lm-min-w-88 {
  min-width: 22rem;
}

.lm-min-w-\[520px\] {
  min-width: 520px;
}

.lm-min-w-110 {
  min-width: 28rem;
}

.lm-min-w-min {
  min-width: -webkit-min-content;
  min-width: -moz-min-content;
  min-width: min-content;
}

.lm-min-w-\[100px\] {
  min-width: 100px;
}

.lm-min-w-\[300px\] {
  min-width: 300px;
}

.lm-min-w-max {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
}

.lm-min-w-\[8px\] {
  min-width: 8px;
}

.lm-min-w-\[12px\] {
  min-width: 12px;
}

.lm-min-w-0 {
  min-width: 0px;
}

.lm-min-w-\[60px\] {
  min-width: 60px;
}

.lm-min-w-16 {
  min-width: 4rem;
}

.lm-min-w-6 {
  min-width: 1.5rem;
}

.lm-min-w-\[420px\] {
  min-width: 420px;
}

.lm-min-w-158 {
  min-width: 40rem;
}

.lm-min-w-\[700px\] {
  min-width: 700px;
}

.lm-min-w-20 {
  min-width: 5rem;
}

.lm-min-w-\[130px\] {
  min-width: 130px;
}

.lm-min-w-\[160px\] {
  min-width: 160px;
}

.lm-min-w-24 {
  min-width: 6rem;
}

.lm-min-w-\[72px\] {
  min-width: 72px;
}

.lm-min-w-\[328px\] {
  min-width: 328px;
}

.lm-min-w-full {
  min-width: 100%;
}

.lm-max-w-88 {
  max-width: 22rem;
}

.lm-max-w-70 {
  max-width: 15rem;
}

.lm-max-w-\[260px\] {
  max-width: 260px;
}

.lm-max-w-72 {
  max-width: 18rem;
}

.lm-max-w-110 {
  max-width: 28rem;
}

.lm-max-w-\[922px\] {
  max-width: 922px;
}

.lm-max-w-48 {
  max-width: 12rem;
}

.lm-max-w-\[300px\] {
  max-width: 300px;
}

.lm-max-w-175 {
  max-width: 43.75rem;
}

.lm-max-w-full {
  max-width: 100%;
}

.lm-max-w-5xl {
  max-width: 60rem;
}

.lm-max-w-118 {
  max-width: 30rem;
}

.lm-max-w-screen-md {
  max-width: 768px;
}

.lm-max-w-96 {
  max-width: 24rem;
}

.lm-max-w-44 {
  max-width: 11rem;
}

.lm-max-w-screen-sm {
  max-width: 640px;
}

.lm-max-w-screen-lg {
  max-width: 1024px;
}

.lm-max-w-screen-xl {
  max-width: 1280px;
}

.lm-max-w-\[70px\] {
  max-width: 70px;
}

.lm-max-w-7xl {
  max-width: 80rem;
}

.lm-max-w-xl {
  max-width: 36rem;
}

.lm-max-w-4xl {
  max-width: 56rem;
}

.lm-max-w-3\/4 {
  max-width: 75%;
}

.lm-max-w-\[250px\] {
  max-width: 250px;
}

.lm-max-w-\[120px\] {
  max-width: 120px;
}

.lm-max-w-xs {
  max-width: 20rem;
}

.lm-max-w-\[500px\] {
  max-width: 500px;
}

.lm-max-w-80 {
  max-width: 20rem;
}

.lm-max-w-\[200px\] {
  max-width: 200px;
}

.lm-max-w-1\/2 {
  max-width: 50%;
}

.lm-max-w-\[7\.25rem\] {
  max-width: 7.25rem;
}

.lm-max-w-\[560px\] {
  max-width: 560px;
}

.lm-max-w-24 {
  max-width: 6rem;
}

.lm-max-w-\[224px\] {
  max-width: 224px;
}

.lm-max-w-102 {
  max-width: 26rem;
}

.lm-max-w-\[620px\] {
  max-width: 620px;
}

.lm-max-w-sm {
  max-width: 24rem;
}

.lm-max-w-lg {
  max-width: 32rem;
}

.lm-max-w-\[700px\] {
  max-width: 700px;
}

.lm-max-w-126 {
  max-width: 32rem;
}

.lm-max-w-max {
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
}

.lm-max-w-6xl {
  max-width: 72rem;
}

.lm-max-w-fit {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.lm-flex-1 {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 0%;
          flex: 1 1 0%;
}

.lm-flex-auto {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}

.lm-flex-3 {
  -webkit-box-flex: 3;
      -ms-flex: 3 3 0%;
          flex: 3 3 0%;
}

.lm-flex-shrink {
  -ms-flex-negative: 1;
      flex-shrink: 1;
}

.lm-flex-shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.lm-shrink-0 {
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.lm-flex-grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.lm-flex-grow-0 {
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
}

.lm-grow {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
}

.lm-basis-2\/5 {
  -ms-flex-preferred-size: 40%;
      flex-basis: 40%;
}

.lm-basis-3\/5 {
  -ms-flex-preferred-size: 60%;
      flex-basis: 60%;
}

.lm-basis-2\/3 {
  -ms-flex-preferred-size: 66.666667%;
      flex-basis: 66.666667%;
}

.lm-basis-1\/3 {
  -ms-flex-preferred-size: 33.333333%;
      flex-basis: 33.333333%;
}

.lm-table-auto {
  table-layout: auto;
}

.lm-table-fixed {
  table-layout: fixed;
}

.lm-origin-center {
  -webkit-transform-origin: center;
          transform-origin: center;
}

.-lm-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-lm-translate-y-1\/2 {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-rotate-90 {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm--rotate-90 {
  --tw-rotate: -90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-rotate-0 {
  --tw-rotate: 0deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-rotate-45 {
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm--rotate-45 {
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-rotate-\[-22\.5deg\] {
  --tw-rotate: -22.5deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-scale-75 {
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.lm-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@-webkit-keyframes lm-pulse {
  50% {
    opacity: .5;
  }
}

@keyframes lm-pulse {
  50% {
    opacity: .5;
  }
}

.lm-animate-pulse {
  -webkit-animation: lm-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
          animation: lm-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.lm-cursor-not-allowed {
  cursor: not-allowed;
}

.lm-cursor-pointer {
  cursor: pointer;
}

.lm-cursor-default {
  cursor: default;
}

.lm-select-none {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.lm-select-auto {
  -webkit-user-select: auto;
     -moz-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.lm-list-inside {
  list-style-position: inside;
}

.lm-list-outside {
  list-style-position: outside;
}

.lm-list-disc {
  list-style-type: disc;
}

.lm-list-decimal {
  list-style-type: decimal;
}

.lm-list-none {
  list-style-type: none;
}

.lm-auto-rows-\[1fr\] {
  grid-auto-rows: 1fr;
}

.lm-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.lm-grid-cols-\[111px_470px_510px\] {
  grid-template-columns: 111px 470px 510px;
}

.lm-grid-cols-\[111px_300px_680px\] {
  grid-template-columns: 111px 300px 680px;
}

.lm-grid-cols-\[auto_60px_1fr\] {
  grid-template-columns: auto 60px 1fr;
}

.lm-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.lm-grid-cols-responsive12 {
  grid-template-columns: repeat(12, 1fr);
}

.lm-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.lm-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.lm-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.lm-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr));
}

.lm-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr));
}

.lm-flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.lm-flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.lm-flex-col-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.lm-flex-wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.lm-place-items-center {
  place-items: center;
}

.lm-content-center {
  -ms-flex-line-pack: center;
      align-content: center;
}

.lm-items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.lm-items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.lm-items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.lm-items-baseline {
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}

.lm-items-stretch {
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
}

.lm-justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.lm-justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.lm-justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.lm-justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.lm-justify-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

.lm-justify-evenly {
  -webkit-box-pack: space-evenly;
      -ms-flex-pack: space-evenly;
          justify-content: space-evenly;
}

.lm-gap-\[30px\] {
  gap: 30px;
}

.lm-gap-5 {
  gap: 1.25rem;
}

.lm-gap-3\.5 {
  gap: 0.875rem;
}

.lm-gap-3 {
  gap: 0.75rem;
}

.lm-gap-4 {
  gap: 1rem;
}

.lm-gap-2 {
  gap: 0.5rem;
}

.lm-gap-\[5px\] {
  gap: 5px;
}

.lm-gap-2\.5 {
  gap: 0.625rem;
}

.lm-gap-6 {
  gap: 1.5rem;
}

.lm-gap-1\.5 {
  gap: 0.375rem;
}

.lm-gap-1 {
  gap: 0.25rem;
}

.lm-gap-0 {
  gap: 0px;
}

.lm-gap-10 {
  gap: 2.5rem;
}

.lm-gap-7 {
  gap: 1.75rem;
}

.lm-gap-7\.5 {
  gap: 1.875rem;
}

.lm-gap-8 {
  gap: 2rem;
}

.lm-gap-\[10px\] {
  gap: 10px;
}

.lm-gap-12 {
  gap: 3rem;
}

.lm-gap-\[6px\] {
  gap: 6px;
}

.lm-gap-\[50px\] {
  gap: 50px;
}

.lm-gap-x-2 {
  -webkit-column-gap: 0.5rem;
     -moz-column-gap: 0.5rem;
          column-gap: 0.5rem;
}

.lm-gap-x-1 {
  -webkit-column-gap: 0.25rem;
     -moz-column-gap: 0.25rem;
          column-gap: 0.25rem;
}

.lm-gap-x-6 {
  -webkit-column-gap: 1.5rem;
     -moz-column-gap: 1.5rem;
          column-gap: 1.5rem;
}

.lm-gap-x-3 {
  -webkit-column-gap: 0.75rem;
     -moz-column-gap: 0.75rem;
          column-gap: 0.75rem;
}

.lm-gap-y-0 {
  row-gap: 0px;
}

.lm-gap-y-1 {
  row-gap: 0.25rem;
}

.lm-gap-y-2 {
  row-gap: 0.5rem;
}

.lm-gap-y-3 {
  row-gap: 0.75rem;
}

.lm-gap-y-4 {
  row-gap: 1rem;
}

.lm-gap-y-5 {
  row-gap: 1.25rem;
}

.lm-gap-y-6 {
  row-gap: 1.5rem;
}

.lm-gap-y-8 {
  row-gap: 2rem;
}

.lm-gap-y-10 {
  row-gap: 2.5rem;
}

.lm-gap-x-7 {
  -webkit-column-gap: 1.75rem;
     -moz-column-gap: 1.75rem;
          column-gap: 1.75rem;
}

.lm-gap-x-4 {
  -webkit-column-gap: 1rem;
     -moz-column-gap: 1rem;
          column-gap: 1rem;
}

.lm-gap-x-5 {
  -webkit-column-gap: 1.25rem;
     -moz-column-gap: 1.25rem;
          column-gap: 1.25rem;
}

.lm-gap-x-\[1\.875rem\] {
  -webkit-column-gap: 1.875rem;
     -moz-column-gap: 1.875rem;
          column-gap: 1.875rem;
}

.lm-gap-x-2\.5 {
  -webkit-column-gap: 0.625rem;
     -moz-column-gap: 0.625rem;
          column-gap: 0.625rem;
}

.lm-gap-y-2\.5 {
  row-gap: 0.625rem;
}

.lm-gap-x-3\.5 {
  -webkit-column-gap: 0.875rem;
     -moz-column-gap: 0.875rem;
          column-gap: 0.875rem;
}

.lm-gap-y-7\.5 {
  row-gap: 1.875rem;
}

.lm-gap-y-7 {
  row-gap: 1.75rem;
}

.lm-gap-x-10 {
  -webkit-column-gap: 2.5rem;
     -moz-column-gap: 2.5rem;
          column-gap: 2.5rem;
}

.lm-gap-y-12 {
  row-gap: 3rem;
}

.lm-gap-y-11 {
  row-gap: 2.75rem;
}

.lm-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse));
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse));
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse));
}

.lm-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2rem * var(--tw-space-x-reverse));
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse));
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse));
}

.lm-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.lm-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.lm-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(10rem * var(--tw-space-x-reverse));
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse));
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
}

.lm-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse));
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse));
}

.lm-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse));
}

.lm-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse));
}

.lm-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse));
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse)));
}

.lm-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(3rem * var(--tw-space-y-reverse));
}

.lm-space-y-\[30px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(30px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(30px * var(--tw-space-y-reverse));
}

.lm-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse));
}

.lm-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse));
}

.lm-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.lm-divide-\[\#D6DDDE\] > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(214 221 222 / var(--tw-divide-opacity));
}

.lm-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1;
  border-color: rgb(203 213 224 / var(--tw-divide-opacity));
}

.lm-self-start {
  -ms-flex-item-align: start;
      align-self: flex-start;
}

.lm-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
}

.lm-self-center {
  -ms-flex-item-align: center;
      align-self: center;
}

.lm-self-stretch {
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.lm-justify-self-start {
  justify-self: start;
}

.lm-justify-self-end {
  justify-self: end;
}

.lm-justify-self-center {
  justify-self: center;
}

.lm-overflow-auto {
  overflow: auto;
}

.lm-overflow-hidden {
  overflow: hidden;
}

.lm-overflow-visible {
  overflow: visible;
}

.\!lm-overflow-visible {
  overflow: visible !important;
}

.lm-overflow-y-auto {
  overflow-y: auto;
}

.lm-overflow-x-hidden {
  overflow-x: hidden;
}

.lm-overflow-y-hidden {
  overflow-y: hidden;
}

.lm-overflow-y-visible {
  overflow-y: visible;
}

.lm-overflow-x-scroll {
  overflow-x: scroll;
}

.lm-overflow-y-scroll {
  overflow-y: scroll;
}

.lm-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.lm-text-ellipsis {
  text-overflow: ellipsis;
}

.lm-whitespace-normal {
  white-space: normal;
}

.lm-whitespace-nowrap {
  white-space: nowrap;
}

.lm-whitespace-pre-line {
  white-space: pre-line;
}

.lm-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.lm-break-words {
  overflow-wrap: break-word;
}

.lm-break-all {
  word-break: break-all;
}

.lm-rounded-card {
  border-radius: 1.25rem;
}

.lm-rounded-2\.5 {
  border-radius: 0.625rem;
}

.lm-rounded-2 {
  border-radius: 0.5rem;
}

.lm-rounded-full {
  border-radius: 9999px;
}

.lm-rounded-lg {
  border-radius: 0.5rem;
}

.lm-rounded-\[5px\] {
  border-radius: 5px;
}

.lm-rounded-md {
  border-radius: 0.375rem;
}

.lm-rounded-sm {
  border-radius: 0.125rem;
}

.lm-rounded-inner-card {
  border-radius: 1rem;
}

.lm-rounded-xl {
  border-radius: 0.75rem;
}

.lm-rounded-2xl {
  border-radius: 1rem;
}

.lm-rounded {
  border-radius: 0.25rem;
}

.lm-rounded-none {
  border-radius: 0px;
}

.lm-rounded-3xl {
  border-radius: 1.5rem;
}

.lm-rounded-header {
  border-radius: 2rem;
}

.lm-rounded-inherit {
  border-radius: inherit;
}

.lm-rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.lm-rounded-b-2\.5 {
  border-bottom-right-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem;
}

.lm-rounded-b-2 {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.lm-rounded-r-lg {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.lm-rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.lm-rounded-b-card {
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}

.lm-rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.lm-rounded-l-full {
  border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px;
}

.lm-rounded-t-2\.5 {
  border-top-left-radius: 0.625rem;
  border-top-right-radius: 0.625rem;
}

.lm-rounded-t-2 {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.lm-rounded-t-card {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
}

.lm-rounded-b-lg {
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.lm-rounded-r-card {
  border-top-right-radius: 1.25rem;
  border-bottom-right-radius: 1.25rem;
}

.lm-rounded-b-inner-card {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.lm-rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.lm-rounded-l-2xl {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.lm-rounded-b-2xl {
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.lm-rounded-r-2xl {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.lm-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem;
}

.lm-rounded-br-lg {
  border-bottom-right-radius: 0.5rem;
}

.lm-border {
  border-width: 1px;
}

.lm-border-0 {
  border-width: 0px;
}

.lm-border-2 {
  border-width: 2px;
}

.\!lm-border-2 {
  border-width: 2px !important;
}

.lm-border-\[1px\] {
  border-width: 1px;
}

.lm-border-4 {
  border-width: 4px;
}

.lm-border-\[7px\] {
  border-width: 7px;
}

.lm-border-b {
  border-bottom-width: 1px;
}

.lm-border-t {
  border-top-width: 1px;
}

.lm-border-r-2 {
  border-right-width: 2px;
}

.lm-border-b-0 {
  border-bottom-width: 0px;
}

.lm-border-r {
  border-right-width: 1px;
}

.lm-border-b-2 {
  border-bottom-width: 2px;
}

.lm-border-b-4 {
  border-bottom-width: 4px;
}

.lm-border-l-\[2px\] {
  border-left-width: 2px;
}

.lm-border-t-4 {
  border-top-width: 4px;
}

.lm-border-t-0 {
  border-top-width: 0px;
}

.lm-border-l-\[1px\] {
  border-left-width: 1px;
}

.lm-border-b-\[1px\] {
  border-bottom-width: 1px;
}

.lm-border-l-2 {
  border-left-width: 2px;
}

.lm-border-l {
  border-left-width: 1px;
}

.lm-border-b-1\/2 {
  border-bottom-width: 0.5px;
}

.lm-border-l-0 {
  border-left-width: 0px;
}

.lm-border-r-0 {
  border-right-width: 0px;
}

.lm-border-solid {
  border-style: solid;
}

.lm-border-dashed {
  border-style: dashed;
}

.lm-border-dotted {
  border-style: dotted;
}

.lm-border-none {
  border-style: none;
}

.lm-border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.lm-border-gray-400 {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 224 / var(--tw-border-opacity));
}

.lm-border-base-navy {
  --tw-border-opacity: 1;
  border-color: rgb(0 35 75 / var(--tw-border-opacity));
}

.lm-border-success {
  --tw-border-opacity: 1;
  border-color: rgb(16 191 141 / var(--tw-border-opacity));
}

.lm-border-error {
  --tw-border-opacity: 1;
  border-color: rgb(223 66 128 / var(--tw-border-opacity));
}

.lm-border-warning {
  --tw-border-opacity: 1;
  border-color: rgb(238 146 26 / var(--tw-border-opacity));
}

.lm-border-base-pink {
  --tw-border-opacity: 1;
  border-color: rgb(253 85 216 / var(--tw-border-opacity));
}

.lm-border-base-blue {
  --tw-border-opacity: 1;
  border-color: rgb(0 35 75 / var(--tw-border-opacity));
}

.lm-border-purple-100 {
  --tw-border-opacity: 1;
  border-color: rgb(240 235 255 / var(--tw-border-opacity));
}

.lm-border-gray-label {
  --tw-border-opacity: 1;
  border-color: rgb(72 101 134 / var(--tw-border-opacity));
}

.lm-border-kogan-dark-gray {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 49 / var(--tw-border-opacity));
}

.lm-border-red-500 {
  --tw-border-opacity: 1;
  border-color: rgb(245 101 101 / var(--tw-border-opacity));
}

.lm-border-purple-700 {
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
}

.lm-border-gray-500 {
  --tw-border-opacity: 1;
  border-color: rgb(160 174 192 / var(--tw-border-opacity));
}

.lm-border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(65 147 241 / var(--tw-border-opacity));
}

.lm-border-pink-500 {
  --tw-border-opacity: 1;
  border-color: rgb(253 85 216 / var(--tw-border-opacity));
}

.lm-border-base-purple {
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
}

.lm-border-subheading {
  --tw-border-opacity: 1;
  border-color: rgb(143 166 170 / var(--tw-border-opacity));
}

.lm-border-purple-200 {
  --tw-border-opacity: 1;
  border-color: rgb(219 207 255 / var(--tw-border-opacity));
}

.lm-border-light-gray {
  --tw-border-opacity: 1;
  border-color: rgb(238 240 245 / var(--tw-border-opacity));
}

.lm-border-base-red {
  --tw-border-opacity: 1;
  border-color: rgb(223 66 128 / var(--tw-border-opacity));
}

.lm-border-green-400 {
  --tw-border-opacity: 1;
  border-color: rgb(104 211 145 / var(--tw-border-opacity));
}

.lm-border-orange-500 {
  --tw-border-opacity: 1;
  border-color: rgb(237 137 54 / var(--tw-border-opacity));
}

.lm-border-green-500 {
  --tw-border-opacity: 1;
  border-color: rgb(72 187 120 / var(--tw-border-opacity));
}

.lm-border-gray-200 {
  --tw-border-opacity: 1;
  border-color: rgb(238 240 245 / var(--tw-border-opacity));
}

.lm-border-background {
  --tw-border-opacity: 1;
  border-color: rgb(248 249 251 / var(--tw-border-opacity));
}

.lm-border-gray-primary {
  --tw-border-opacity: 1;
  border-color: rgb(93 114 135 / var(--tw-border-opacity));
}

.lm-border-gray-600 {
  --tw-border-opacity: 1;
  border-color: rgb(113 128 150 / var(--tw-border-opacity));
}

.lm-border-input {
  --tw-border-opacity: 1;
  border-color: rgb(93 114 135 / var(--tw-border-opacity));
}

.lm-border-transparent {
  border-color: transparent;
}

.lm-border-l-base-purple {
  --tw-border-opacity: 1;
  border-left-color: rgb(98 69 181 / var(--tw-border-opacity));
}

.lm-border-b-gray-primary {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(93 114 135 / var(--tw-border-opacity));
}

.lm-bg-background {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.lm-bg-gray-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.lm-bg-transparent {
  background-color: transparent;
}

.lm-bg-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.lm-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.lm-bg-muted-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(20 51 88 / var(--tw-bg-opacity));
}

.lm-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.lm-bg-purple-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(240 235 255 / var(--tw-bg-opacity));
}

.lm-bg-gray-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(93 114 135 / var(--tw-bg-opacity));
}

.lm-bg-green-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(244 254 251 / var(--tw-bg-opacity));
}

.lm-bg-red-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 244 244 / var(--tw-bg-opacity));
}

.lm-bg-orange-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 250 238 / var(--tw-bg-opacity));
}

.lm-bg-pink-background {
  --tw-bg-opacity: 1;
  background-color: rgb(255 245 253 / var(--tw-bg-opacity));
}

.lm-bg-pink-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 236 250 / var(--tw-bg-opacity));
}

.lm-bg-glacier-gray-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity));
}

.lm-bg-base-blue {
  --tw-bg-opacity: 1;
  background-color: rgb(0 35 75 / var(--tw-bg-opacity));
}

.lm-bg-label-copy {
  --tw-bg-opacity: 1;
  background-color: rgb(72 101 134 / var(--tw-bg-opacity));
}

.lm-bg-purple-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(85 60 154 / var(--tw-bg-opacity));
}

.lm-bg-base-pink {
  --tw-bg-opacity: 1;
  background-color: rgb(253 85 216 / var(--tw-bg-opacity));
}

.lm-bg-openpay-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 184 28 / var(--tw-bg-opacity));
}

.lm-bg-kogan-dark-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 49 / var(--tw-bg-opacity));
}

.lm-bg-light-gray {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.lm-bg-base-purple {
  --tw-bg-opacity: 1;
  background-color: rgb(98 69 181 / var(--tw-bg-opacity));
}

.lm-bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(219 207 255 / var(--tw-bg-opacity));
}

.lm-bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(160 174 192 / var(--tw-bg-opacity));
}

.lm-bg-yellow-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 252 191 / var(--tw-bg-opacity));
}

.lm-bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(229 62 62 / var(--tw-bg-opacity));
}

.lm-bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 215 215 / var(--tw-bg-opacity));
}

.lm-bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(72 187 120 / var(--tw-bg-opacity));
}

.lm-bg-gray-400 {
  --tw-bg-opacity: 1;
  background-color: rgb(203 213 224 / var(--tw-bg-opacity));
}

.lm-bg-base-navy {
  --tw-bg-opacity: 1;
  background-color: rgb(0 35 75 / var(--tw-bg-opacity));
}

.lm-bg-gray-label {
  --tw-bg-opacity: 1;
  background-color: rgb(72 101 134 / var(--tw-bg-opacity));
}

.lm-bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(253 85 216 / var(--tw-bg-opacity));
}

.lm-bg-burnt-yellow-100 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 250 238 / var(--tw-bg-opacity));
}

.lm-bg-gray-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(113 128 150 / var(--tw-bg-opacity));
}

.lm-bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(74 85 104 / var(--tw-bg-opacity));
}

.lm-bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(45 55 72 / var(--tw-bg-opacity));
}

.lm-bg-gray-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(26 32 44 / var(--tw-bg-opacity));
}

.lm-bg-copy {
  --tw-bg-opacity: 1;
  background-color: rgb(93 114 135 / var(--tw-bg-opacity));
}

.lm-bg-base-red {
  --tw-bg-opacity: 1;
  background-color: rgb(223 66 128 / var(--tw-bg-opacity));
}

.lm-bg-burnt-yellow-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 170 5 / var(--tw-bg-opacity));
}

.lm-bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(65 147 241 / var(--tw-bg-opacity));
}

.lm-bg-success {
  --tw-bg-opacity: 1;
  background-color: rgb(16 191 141 / var(--tw-bg-opacity));
}

.lm-bg-subheading {
  --tw-bg-opacity: 1;
  background-color: rgb(143 166 170 / var(--tw-bg-opacity));
}

.lm-bg-green-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(154 230 180 / var(--tw-bg-opacity));
}

.lm-bg-purple-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(98 69 181 / var(--tw-bg-opacity));
}

.lm-bg-base-yellow {
  --tw-bg-opacity: 1;
  background-color: rgb(255 188 59 / var(--tw-bg-opacity));
}

.lm-bg-light-purple-bg {
  --tw-bg-opacity: 1;
  background-color: rgb(246 244 255 / var(--tw-bg-opacity));
}

.lm-bg-\[\#F8F9FB\] {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.lm-bg-modal-overlay {
  background-color: rgba(93,114,135,.5);
}

.lm-bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(245 101 101 / var(--tw-bg-opacity));
}

.lm-bg-green-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(198 246 213 / var(--tw-bg-opacity));
}

.lm-bg-inherit {
  background-color: inherit;
}

.\!lm-bg-transparent {
  background-color: transparent !important;
}

.lm-bg-\[\#e2e4e9\] {
  --tw-bg-opacity: 1;
  background-color: rgb(226 228 233 / var(--tw-bg-opacity));
}

.lm-bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(254 235 200 / var(--tw-bg-opacity));
}

.\!lm-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity)) !important;
}

.lm-bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(236 201 75 / var(--tw-bg-opacity));
}

.lm-bg-white\/30 {
  background-color: rgb(255 255 255 / 0.3);
}

.lm-bg-purple-600 {
  --tw-bg-opacity: 1;
  background-color: rgb(117 82 217 / var(--tw-bg-opacity));
}

.lm-bg-pink-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(255 166 236 / var(--tw-bg-opacity));
}

.lm-bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.lm-bg-opacity-50 {
  --tw-bg-opacity: 0.5;
}

.lm-bg-none {
  background-image: none;
}

.lm-fill-current {
  fill: currentColor;
}

.lm-fill-base-blue {
  fill: #00234B;
}

.lm-fill-white {
  fill: #FFFFFF;
}

.lm-fill-base-pink {
  fill: #FD55D8;
}

.lm-fill-base-navy {
  fill: #00234B;
}

.lm-fill-gray-500 {
  fill: #A0AEC0;
}

.lm-fill-green-500 {
  fill: #48BB78;
}

.lm-fill-success {
  fill: #10BF8D;
}

.lm-object-contain {
  -o-object-fit: contain;
     object-fit: contain;
}

.lm-object-cover {
  -o-object-fit: cover;
     object-fit: cover;
}

.lm-p-7 {
  padding: 1.75rem;
}

.lm-p-5 {
  padding: 1.25rem;
}

.lm-p-2 {
  padding: 0.5rem;
}

.lm-p-4 {
  padding: 1rem;
}

.lm-p-3 {
  padding: 0.75rem;
}

.lm-p-0 {
  padding: 0px;
}

.lm-p-1 {
  padding: 0.25rem;
}

.lm-p-6 {
  padding: 1.5rem;
}

.lm-p-8 {
  padding: 2rem;
}

.lm-p-10 {
  padding: 2.5rem;
}

.lm-p-12 {
  padding: 3rem;
}

.lm-p-16 {
  padding: 4rem;
}

.lm-p-\[30px\] {
  padding: 30px;
}

.lm-p-7\.5 {
  padding: 1.875rem;
}

.lm-p-20 {
  padding: 5rem;
}

.lm-p-2\.5 {
  padding: 0.625rem;
}

.lm-p-\[18px\] {
  padding: 18px;
}

.lm-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.lm-py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.lm-px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.lm-py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.lm-px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.lm-px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.lm-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.lm-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.lm-px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.lm-py-5\.5 {
  padding-top: 1.125rem;
  padding-bottom: 1.125rem;
}

.lm-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.lm-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.lm-px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.lm-px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.lm-px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.lm-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.lm-py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.lm-py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.lm-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.lm-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.lm-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.lm-px-\[30px\] {
  padding-left: 30px;
  padding-right: 30px;
}

.lm-py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.lm-px-20 {
  padding-left: 5rem;
  padding-right: 5rem;
}

.lm-py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.lm-py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.lm-py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px;
}

.lm-py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.lm-px-\[39px\] {
  padding-left: 39px;
  padding-right: 39px;
}

.lm-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.lm-px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.lm-py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.lm-px-7\.5 {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
}

.lm-py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.lm-px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.lm-px-\[6px\] {
  padding-left: 6px;
  padding-right: 6px;
}

.lm-px-\[18px\] {
  padding-left: 18px;
  padding-right: 18px;
}

.lm-px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.lm-px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.lm-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.lm-px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.lm-py-\[18px\] {
  padding-top: 18px;
  padding-bottom: 18px;
}

.\!lm-pr-28 {
  padding-right: 7rem !important;
}

.lm-pb-\[1px\] {
  padding-bottom: 1px;
}

.lm-pb-4 {
  padding-bottom: 1rem;
}

.lm-pt-3 {
  padding-top: 0.75rem;
}

.lm-pb-8 {
  padding-bottom: 2rem;
}

.lm-pt-8 {
  padding-top: 2rem;
}

.lm-pr-3 {
  padding-right: 0.75rem;
}

.lm-pt-4 {
  padding-top: 1rem;
}

.lm-pb-9 {
  padding-bottom: 2.25rem;
}

.lm-pl-0 {
  padding-left: 0px;
}

.lm-pl-1 {
  padding-left: 0.25rem;
}

.lm-pl-2 {
  padding-left: 0.5rem;
}

.lm-pl-3 {
  padding-left: 0.75rem;
}

.lm-pl-4 {
  padding-left: 1rem;
}

.lm-pl-5 {
  padding-left: 1.25rem;
}

.lm-pl-6 {
  padding-left: 1.5rem;
}

.lm-pl-8 {
  padding-left: 2rem;
}

.lm-pl-10 {
  padding-left: 2.5rem;
}

.lm-pr-0 {
  padding-right: 0px;
}

.lm-pr-1 {
  padding-right: 0.25rem;
}

.lm-pr-2 {
  padding-right: 0.5rem;
}

.lm-pr-4 {
  padding-right: 1rem;
}

.lm-pr-5 {
  padding-right: 1.25rem;
}

.lm-pr-6 {
  padding-right: 1.5rem;
}

.lm-pr-8 {
  padding-right: 2rem;
}

.lm-pr-10 {
  padding-right: 2.5rem;
}

.lm-pt-0 {
  padding-top: 0px;
}

.lm-pt-1 {
  padding-top: 0.25rem;
}

.lm-pt-2 {
  padding-top: 0.5rem;
}

.lm-pt-5 {
  padding-top: 1.25rem;
}

.lm-pt-6 {
  padding-top: 1.5rem;
}

.lm-pt-10 {
  padding-top: 2.5rem;
}

.lm-pb-0 {
  padding-bottom: 0px;
}

.lm-pb-1 {
  padding-bottom: 0.25rem;
}

.lm-pb-2 {
  padding-bottom: 0.5rem;
}

.lm-pb-3 {
  padding-bottom: 0.75rem;
}

.lm-pb-5 {
  padding-bottom: 1.25rem;
}

.lm-pb-6 {
  padding-bottom: 1.5rem;
}

.lm-pb-10 {
  padding-bottom: 2.5rem;
}

.lm-pr-3\.5 {
  padding-right: 0.875rem;
}

.lm-pl-3\.5 {
  padding-left: 0.875rem;
}

.lm-pr-9 {
  padding-right: 2.25rem;
}

.lm-pr-12 {
  padding-right: 3rem;
}

.lm-pt-20 {
  padding-top: 5rem;
}

.lm-pb-11 {
  padding-bottom: 2.75rem;
}

.lm-pt-16 {
  padding-top: 4rem;
}

.lm-pl-7\.5 {
  padding-left: 1.875rem;
}

.lm-pl-7 {
  padding-left: 1.75rem;
}

.lm-pb-20 {
  padding-bottom: 5rem;
}

.lm-pb-12 {
  padding-bottom: 3rem;
}

.lm-pt-40 {
  padding-top: 10rem;
}

.lm-pl-\[15px\] {
  padding-left: 15px;
}

.lm-pt-24 {
  padding-top: 6rem;
}

.lm-pr-\[10px\] {
  padding-right: 10px;
}

.lm-pl-\[10px\] {
  padding-left: 10px;
}

.lm-pb-40 {
  padding-bottom: 10rem;
}

.lm-pb-7 {
  padding-bottom: 1.75rem;
}

.lm-pl-2\.5 {
  padding-left: 0.625rem;
}

.lm-pt-\[3px\] {
  padding-top: 3px;
}

.lm-pt-12 {
  padding-top: 3rem;
}

.lm-pb-14 {
  padding-bottom: 3.5rem;
}

.lm-pl-px {
  padding-left: 1px;
}

.lm-pt-32 {
  padding-top: 8rem;
}

.lm-text-left {
  text-align: left;
}

.lm-text-center {
  text-align: center;
}

.lm-text-right {
  text-align: right;
}

.lm-text-start {
  text-align: start;
}

.lm-align-top {
  vertical-align: top;
}

.lm-align-middle {
  vertical-align: middle;
}

.lm-align-text-bottom {
  vertical-align: text-bottom;
}

.lm-font-demi {
  font-family: AvenirNextLTPro-Demi;
}

.lm-font-proMedium {
  font-family: AvenirNextLTPro-Medium;
}

.lm-font-body {
  font-family: AvenirNext;
}

.lm-font-display {
  font-family: AvenirNext;
}

.\!lm-font-proMedium {
  font-family: AvenirNextLTPro-Medium !important;
}

.lm-text-heading-two {
  font-size: 2.25rem;
}

.lm-text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.lm-text-sm {
  font-size: 0.875rem;
}

.lm-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.lm-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.lm-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.lm-text-xxs {
  font-size: 0.625rem;
}

.lm-text-heading-four {
  font-size: 1.625rem;
}

.lm-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.lm-text-\[12pt\] {
  font-size: 12pt;
}

.lm-text-\[10pt\] {
  font-size: 10pt;
}

.lm-text-\[14px\] {
  font-size: 14px;
}

.lm-text-heading-one {
  font-size: 2.625rem;
}

.lm-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.lm-text-mobile-heading-one {
  font-size: 2.25rem;
}

.lm-text-mobile-heading-two {
  font-size: 1.75rem;
}

.lm-text-mobile-heading-three {
  font-size: 1.5rem;
}

.lm-text-mobile-heading-four {
  font-size: 1.25rem;
}

.lm-text-mobile-heading-five {
  font-size: 1rem;
}

.lm-text-mobile-heading-six {
  font-size: 0.875rem;
}

.lm-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.lm-text-heading-five {
  font-size: 1.25rem;
}

.lm-text-\[10px\] {
  font-size: 10px;
}

.lm-text-\[22px\] {
  font-size: 22px;
}

.lm-text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.lm-text-heading-six {
  font-size: 1rem;
}

.lm-text-\[27px\] {
  font-size: 27px;
}

.lm-text-\[18px\] {
  font-size: 18px;
}

.lm-text-heading-three {
  font-size: 2rem;
}

.lm-text-onboarding-subtitle {
  font-size: 1.125rem;
}

.lm-font-semibold {
  font-weight: 600;
}

.lm-font-normal {
  font-weight: 400;
}

.lm-font-bold {
  font-weight: 700;
}

.lm-font-medium {
  font-weight: 500;
}

.lm-font-extrabold {
  font-weight: 800;
}

.lm-font-light {
  font-weight: 300;
}

.lm-uppercase {
  text-transform: uppercase;
}

.lm-lowercase {
  text-transform: lowercase;
}

.lm-capitalize {
  text-transform: capitalize;
}

.lm-italic {
  font-style: italic;
}

.lm-leading-6 {
  line-height: 1.5rem;
}

.lm-leading-7 {
  line-height: 1.75rem;
}

.lm-leading-4 {
  line-height: 1rem;
}

.lm-leading-none {
  line-height: 1;
}

.lm-leading-10 {
  line-height: 2.5rem;
}

.lm-leading-5 {
  line-height: 1.25rem;
}

.lm-leading-8 {
  line-height: 2rem;
}

.lm-leading-heading-one {
  line-height: 2.625rem;
}

.lm-leading-snug {
  line-height: 1.375;
}

.lm-leading-heading-five {
  line-height: 1.5rem;
}

.lm-leading-9 {
  line-height: 2.25rem;
}

.lm-leading-12 {
  line-height: 3rem;
}

.lm-leading-heading-four {
  line-height: 2rem;
}

.lm-leading-mobile-heading-five {
  line-height: 1.25rem;
}

.lm-leading-tight {
  line-height: 1.25;
}

.lm-leading-relaxed {
  line-height: 1.625;
}

.lm-tracking-subheading {
  letter-spacing: 0.25rem;
}

.lm-tracking-\[4px\] {
  letter-spacing: 4px;
}

.lm-tracking-widest {
  letter-spacing: 0.1em;
}

.lm-tracking-\[0\.22px\] {
  letter-spacing: 0.22px;
}

.lm-text-base-purple {
  --tw-text-opacity: 1;
  color: rgb(98 69 181 / var(--tw-text-opacity));
}

.lm-text-base-blue {
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
}

.lm-text-base-navy {
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
}

.lm-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
}

.lm-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.lm-text-gray-300 {
  --tw-text-opacity: 1;
  color: rgb(226 232 240 / var(--tw-text-opacity));
}

.lm-text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.lm-text-gray-primary {
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
}

.lm-text-base-pink {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.lm-text-purple-700 {
  --tw-text-opacity: 1;
  color: rgb(98 69 181 / var(--tw-text-opacity));
}

.lm-text-gray-subheading {
  --tw-text-opacity: 1;
  color: rgb(143 166 170 / var(--tw-text-opacity));
}

.lm-text-success {
  --tw-text-opacity: 1;
  color: rgb(16 191 141 / var(--tw-text-opacity));
}

.lm-text-copy {
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
}

.lm-text-subheading {
  --tw-text-opacity: 1;
  color: rgb(143 166 170 / var(--tw-text-opacity));
}

.lm-text-gray-label {
  --tw-text-opacity: 1;
  color: rgb(72 101 134 / var(--tw-text-opacity));
}

.lm-text-warning {
  --tw-text-opacity: 1;
  color: rgb(238 146 26 / var(--tw-text-opacity));
}

.lm-text-burnt-yellow-600 {
  --tw-text-opacity: 1;
  color: rgb(255 170 5 / var(--tw-text-opacity));
}

.lm-text-icon-grey {
  --tw-text-opacity: 1;
  color: rgb(72 101 134 / var(--tw-text-opacity));
}

.lm-text-purple-300 {
  --tw-text-opacity: 1;
  color: rgb(185 161 255 / var(--tw-text-opacity));
}

.lm-text-error {
  --tw-text-opacity: 1;
  color: rgb(223 66 128 / var(--tw-text-opacity));
}

.lm-text-red-600 {
  --tw-text-opacity: 1;
  color: rgb(229 62 62 / var(--tw-text-opacity));
}

.lm-text-gray-600 {
  --tw-text-opacity: 1;
  color: rgb(113 128 150 / var(--tw-text-opacity));
}

.lm-text-base-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 188 59 / var(--tw-text-opacity));
}

.lm-text-purple-800 {
  --tw-text-opacity: 1;
  color: rgb(85 60 154 / var(--tw-text-opacity));
}

.lm-text-input {
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
}

.lm-text-openpay-yellow {
  --tw-text-opacity: 1;
  color: rgb(255 184 28 / var(--tw-text-opacity));
}

.lm-text-kogan-dark-gray {
  --tw-text-opacity: 1;
  color: rgb(51 51 49 / var(--tw-text-opacity));
}

.lm-text-label-copy {
  --tw-text-opacity: 1;
  color: rgb(72 101 134 / var(--tw-text-opacity));
}

.lm-text-red-500 {
  --tw-text-opacity: 1;
  color: rgb(245 101 101 / var(--tw-text-opacity));
}

.lm-text-base-red {
  --tw-text-opacity: 1;
  color: rgb(223 66 128 / var(--tw-text-opacity));
}

.lm-text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(203 213 224 / var(--tw-text-opacity));
}

.lm-text-gray-800 {
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
}

.lm-text-purple-900 {
  --tw-text-opacity: 1;
  color: rgb(71 49 131 / var(--tw-text-opacity));
}

.lm-text-orange-500 {
  --tw-text-opacity: 1;
  color: rgb(237 137 54 / var(--tw-text-opacity));
}

.lm-text-gray-700 {
  --tw-text-opacity: 1;
  color: rgb(74 85 104 / var(--tw-text-opacity));
}

.lm-text-green-500 {
  --tw-text-opacity: 1;
  color: rgb(72 187 120 / var(--tw-text-opacity));
}

.lm-text-blue-900 {
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
}

.lm-text-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(65 147 241 / var(--tw-text-opacity));
}

.lm-text-inherit {
  color: inherit;
}

.lm-text-\[\#FFBD2C\] {
  --tw-text-opacity: 1;
  color: rgb(255 189 44 / var(--tw-text-opacity));
}

.lm-text-\[\#E46464\] {
  --tw-text-opacity: 1;
  color: rgb(228 100 100 / var(--tw-text-opacity));
}

.lm-text-green-800 {
  --tw-text-opacity: 1;
  color: rgb(39 103 73 / var(--tw-text-opacity));
}

.lm-text-red-800 {
  --tw-text-opacity: 1;
  color: rgb(155 44 44 / var(--tw-text-opacity));
}

.lm-text-gray-900 {
  --tw-text-opacity: 1;
  color: rgb(26 32 44 / var(--tw-text-opacity));
}

.lm-text-gray-200 {
  --tw-text-opacity: 1;
  color: rgb(238 240 245 / var(--tw-text-opacity));
}

.lm-text-pink-600 {
  --tw-text-opacity: 1;
  color: rgb(228 34 186 / var(--tw-text-opacity));
}

.lm-text-green-600 {
  --tw-text-opacity: 1;
  color: rgb(56 161 105 / var(--tw-text-opacity));
}

.lm-text-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(236 201 75 / var(--tw-text-opacity));
}

.lm-text-pink-900 {
  --tw-text-opacity: 1;
  color: rgb(107 18 90 / var(--tw-text-opacity));
}

.lm-text-red-900 {
  --tw-text-opacity: 1;
  color: rgb(116 42 42 / var(--tw-text-opacity));
}

.lm-text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(34 84 61 / var(--tw-text-opacity));
}

.lm-text-yellow-900 {
  --tw-text-opacity: 1;
  color: rgb(116 66 16 / var(--tw-text-opacity));
}

.lm-text-yellow-800 {
  --tw-text-opacity: 1;
  color: rgb(151 90 22 / var(--tw-text-opacity));
}

.lm-text-burnt-yellow-500 {
  --tw-text-opacity: 1;
  color: rgb(255 188 59 / var(--tw-text-opacity));
}

.lm-text-purple-500 {
  --tw-text-opacity: 1;
  color: rgb(142 108 240 / var(--tw-text-opacity));
}

.lm-text-purple-600 {
  --tw-text-opacity: 1;
  color: rgb(117 82 217 / var(--tw-text-opacity));
}

.lm-text-\[\#5437A8\] {
  --tw-text-opacity: 1;
  color: rgb(84 55 168 / var(--tw-text-opacity));
}

.lm-text-purple-200 {
  --tw-text-opacity: 1;
  color: rgb(219 207 255 / var(--tw-text-opacity));
}

.lm-text-green-dark {
  --tw-text-opacity: 1;
  color: rgb(24 164 124 / var(--tw-text-opacity));
}

.lm-text-blue-subheading {
  --tw-text-opacity: 1;
  color: rgb(70 100 137 / var(--tw-text-opacity));
}

.lm-underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.lm-line-through {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through;
}

.lm-no-underline {
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.lm-decoration-error {
  -webkit-text-decoration-color: #DF4280;
          text-decoration-color: #DF4280;
}

.lm-decoration-success {
  -webkit-text-decoration-color: #10BF8D;
          text-decoration-color: #10BF8D;
}

.lm-decoration-warning {
  -webkit-text-decoration-color: #EE921A;
          text-decoration-color: #EE921A;
}

.lm-decoration-dashed {
  -webkit-text-decoration-style: dashed;
          text-decoration-style: dashed;
}

.lm-underline-offset-4 {
  text-underline-offset: 4px;
}

.lm-underline-offset-2 {
  text-underline-offset: 2px;
}

.lm-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.lm-placeholder-input::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(93 114 135 / var(--tw-placeholder-opacity));
}

.lm-placeholder-input::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(93 114 135 / var(--tw-placeholder-opacity));
}

.lm-placeholder-input::-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(93 114 135 / var(--tw-placeholder-opacity));
}

.lm-placeholder-input::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgb(93 114 135 / var(--tw-placeholder-opacity));
}

.lm-opacity-80 {
  opacity: 0.8;
}

.lm-opacity-0 {
  opacity: 0;
}

.lm-opacity-90 {
  opacity: 0.9;
}

.lm-opacity-10 {
  opacity: 0.1;
}

.lm-opacity-20 {
  opacity: 0.2;
}

.lm-shadow-none {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-table {
  --tw-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  --tw-shadow-colored: 0px 4px 20px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-100 {
  --tw-shadow: 0px 2px 3px rgba(26, 32, 44, 0.12), 0px 2px 10px rgba(26, 32, 44, 0.07);
  --tw-shadow-colored: 0px 2px 3px var(--tw-shadow-color), 0px 2px 10px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-200 {
  --tw-shadow: 0px 6px 14px rgba(26, 32, 44, 0.08), 0px 2px 6px rgba(26, 32, 44, 0.07);
  --tw-shadow-colored: 0px 6px 14px var(--tw-shadow-color), 0px 2px 6px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-300 {
  --tw-shadow: 0px 20px 50px rgba(107, 113, 125, 0.25);
  --tw-shadow-colored: 0px 20px 50px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-universal {
  --tw-shadow: 0px 2px 2px rgba(26, 32, 44, 0.12), 0px 1px 3px rgba(26, 32, 44, 0.01);
  --tw-shadow-colored: 0px 2px 2px var(--tw-shadow-color), 0px 1px 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-button {
  --tw-shadow: 0px 2px 1px rgba(0, 35, 75, 0.04), 0px 3px 4px rgba(74, 85, 104, 0.21);
  --tw-shadow-colored: 0px 2px 1px var(--tw-shadow-color), 0px 3px 4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-button-active {
  --tw-shadow: 0px 1px 1px rgba(0, 35, 75, 0.04), 0px 1px 1px rgba(74, 85, 104, 0.21);
  --tw-shadow-colored: 0px 1px 1px var(--tw-shadow-color), 0px 1px 1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-modal {
  --tw-shadow: 0px 20px 50px rgba(0, 35, 75, 0.65);
  --tw-shadow-colored: 0px 20px 50px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-popover {
  --tw-shadow: 0px 1px 16px rgba(26, 32, 44, 0.12);
  --tw-shadow-colored: 0px 1px 16px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-shadow-input-outline {
  --tw-shadow: 0 0 0 2px #6245B5;
  --tw-shadow-colored: 0 0 0 2px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.lm-outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.lm-outline {
  outline-style: solid;
}

.lm-outline-4 {
  outline-width: 4px;
}

.lm-outline-offset-\[-2px\] {
  outline-offset: -2px;
}

.lm-outline-burnt-yellow-500 {
  outline-color: #FFBC3B;
}

.lm-drop-shadow-\[0_1px_2px_rgba\(0\,0\,0\,0\.75\)\] {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0,0,0,0.75));
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
          filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.lm-transition {
  -webkit-transition-property: color, background-color, border-color, fill, stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, fill, stroke, opacity, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter, -webkit-backdrop-filter;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.lm-transition-transform {
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.lm-transition-opacity {
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.lm-transition-colors {
  -webkit-transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, fill, stroke, -webkit-text-decoration-color;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, -webkit-text-decoration-color;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.lm-duration-200 {
  -webkit-transition-duration: 200ms;
          transition-duration: 200ms;
}

.lm-duration-300 {
  -webkit-transition-duration: 300ms;
          transition-duration: 300ms;
}

.lm-duration-500 {
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}

.lm-ease-out {
  -webkit-transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

.lm-content-none {
  --tw-content: none;
  content: var(--tw-content);
}

.lm-alternate-child-bg > :nth-child(even) {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.lm-keyboard-only-focus:focus:not(:focus-visible) {
  outline: none;
}

.lm-keyboard-only-focus:focus-visible {
  outline-color: #FFBC3B;
}

.lm-stack-horizontal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.lm-stack-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.lm-stack-xs.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 0.25rem;
  margin-bottom: 0;
}

.lm-stack-xs.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 0.25rem;
  margin-right: 0;
}

.lm-stack-s.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 0.5rem;
  margin-bottom: 0;
}

.lm-stack-s.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 0.5rem;
  margin-right: 0;
}

.lm-stack-m.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 0.75rem;
  margin-bottom: 0;
}

.lm-stack-m.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 0.75rem;
  margin-right: 0;
}

.lm-stack-xm.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 0.875rem;
  margin-bottom: 0;
}

.lm-stack-xm.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 0.875rem;
  margin-right: 0;
}

.lm-stack-r.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 1rem;
  margin-bottom: 0;
}

.lm-stack-r.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 1rem;
  margin-right: 0;
}

.lm-stack-l.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 1.5rem;
  margin-bottom: 0;
}

.lm-stack-l.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 1.5rem;
  margin-right: 0;
}

.lm-stack-xl.lm-stack-horizontal > *:not(:last-child) {
  margin-right: 2rem;
  margin-bottom: 0;
}

.lm-stack-xl.lm-stack-vertical > *:not(:last-child) {
  margin-bottom: 2rem;
  margin-right: 0;
}

body {
  font-family: AvenirNext;
  height: 100vh;
  -webkit-font-smoothing: antialiased;
}

abbr[title] {
  border-bottom: none;
  text-decoration: none;
  -webkit-text-decoration: none;
}

abbr {
  position: relative;
}

abbr::after {
  opacity: 0;
  visibility: hidden;
}

@-webkit-keyframes showAbbr {
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes showAbbr {
  to {
    opacity: 1;
    visibility: visible;
  }
}

abbr:not(.notTruncated):hover::after {
  font-family: AvenirNext;
  font-weight: 400;
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
  content: attr(data-title);
  position: absolute;
  bottom: 100%;
  left: 0;
  display: block;
  background: #ffffff;
  border: 1px solid #cbd5e0;
  border-radius: 5px;
  padding: 3px 8px;
  font-size: 14px;
  z-index: 10;
  -webkit-animation: showAbbr 0.2s forwards;
          animation: showAbbr 0.2s forwards;
  -webkit-animation-delay: 500ms;
          animation-delay: 500ms;
}

.lumi_field {
  --tw-text-opacity: 1;
  color: rgb(72 101 134 / var(--tw-text-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  border-radius: 0.625rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 224 / var(--tw-border-opacity));
  width: 100%;
  font-size: 0.875rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  /*
    This ensures that all inputs are the same height. Without this, inputs with
    type 'date' are taller than text inputs
  */
  min-height: 3.35em;
}

.lumi_field[type='date']::-webkit-inner-spin-button,
.lumi_field[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.lumi_field:disabled {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
  cursor: not-allowed;
}

.lumi_field:focus {
  --tw-border-opacity: 1;
  border-color: rgb(253 85 216 / var(--tw-border-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.lumi_borderless_field {
  text-align: center;
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
  border-style: none;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.lumi_borderless_field:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.lumi_toggle {
  width: 36px;
  height: 20px;
  border-radius: 20px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  border-style: solid;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(203 213 224 / var(--tw-border-opacity));
  position: relative;
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.lumi_checkbox {
  z-index: 10;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  position: relative;
  outline: 2px solid transparent;
  outline-offset: 2px;
  cursor: pointer;
}

.lumi_toggle_knob {
  position: absolute;
  border-radius: 9999px;
  content: '';
  left: 11.11%;
  top: 13%;
  bottom: 13%;
  right: 50%;
  width: 14px;
  height: 14px;
  --tw-bg-opacity: 1;
  background-color: rgb(160 174 192 / var(--tw-bg-opacity));
  -webkit-transition: 0.3s ease all;
  transition: 0.3s ease all;
}

.lumi_toggle_knob_checked {
  left: 50%;
  right: 11.11%;
}

.lm-focus-border {
  position: relative;
}

.lm-focus-border:after {
  content: '';
  border-radius: inherit;
  pointer-events: none;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  position: absolute;
  -webkit-transition: opacity 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  transition: opacity 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  opacity: 0;
}

.lm-focus-border:focus:after {
  opacity: 1;
}

.lm-focus-yellow:focus {
  -webkit-box-shadow: #ffbd26 0px 0px 0px 3px;
          box-shadow: #ffbd26 0px 0px 0px 3px;
  border-color: transparent;
}

.lm-focus-yellow-inset:focus {
  -webkit-box-shadow: inset #ffbd26 0px 0px 0px 3px;
          box-shadow: inset #ffbd26 0px 0px 0px 3px;
  border-color: transparent;
}

.lm-focus-border.lm-focus-border-invert:after {
  --tw-border-opacity: 1;
  border-color: rgb(231 237 245 / var(--tw-border-opacity));
}

.lm-popover-child {
  --tw-shadow: 0px 2px 2px rgba(26, 32, 44, 0.12), 0px 1px 3px rgba(26, 32, 44, 0.01);
  --tw-shadow-colored: 0px 2px 2px var(--tw-shadow-color), 0px 1px 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: absolute;
  top: 2.875rem;
  padding: 0.5rem;
  z-index: 1000;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.lm-popover-child-align-left {
  right: 0;
}

.lm-popover-child-align-top {
  top: unset;
  bottom: 100%;
  margin-bottom: 0.75rem;
}

.lm-popover-child-align-right {
  top: 0;
  left: 100%;
}

.lm-bg-progress {
  background: -webkit-gradient(linear, left top, left bottom, from(#ffdd32), to(#ffbd26));
  background: linear-gradient(#ffdd32, #ffbd26);
  -webkit-box-shadow: 1px 1px 0px #ecab17;
          box-shadow: 1px 1px 0px #ecab17;
}

.backdrop {
  border-radius: 0.25rem;
  position: absolute;
  z-index: 10;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  pointer-events: none;
}

.backdrop::after {
  content: '';
  position: absolute;
  top: -0.05rem;
  right: -0.05rem;
  bottom: -0.05rem;
  left: -0.05rem;
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  transition: opacity 0.2s cubic-bezier(0.64, 0, 0.35, 1);
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
  border-width: 2px;
}

.lumi_field:focus ~ .backdrop:after {
  opacity: 1;
}

@-webkit-keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

@keyframes loadingFade {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.8;
  }

  100% {
    opacity: 0;
  }
}

.lm-bg-pink-gradient {
  background: -webkit-gradient(linear, left top, right top, from(#fd55d8), to(#ec30c2));
  background: linear-gradient(to right, #fd55d8, #ec30c2);
}

.lm-bg-yellow-gradient {
  background: -webkit-gradient(linear, left top, right top, from(#ffdb32), to(#ffbd26));
  background: linear-gradient(to right, #ffdb32, #ffbd26);
}

.lm-bg-green-gradient {
  background: -webkit-gradient(linear, left top, right top, from(#10bf8d), to(#00a2a2));
  background: linear-gradient(to right, #10bf8d, #00a2a2);
}

.lm-h-fit-content {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

/**
Responsive classes for the Stack component
*/

/**
  PropertyImageGallery component custom styles
*/

.property-image-gallery.image-gallery .image-gallery-image {
  height: 387px;
  -o-object-fit: cover;
     object-fit: cover;
}

/* https://developers.google.com/recaptcha/docs/faq#id-like-to-hide-the-recaptcha-badge.-what-is-allowed */

.grecaptcha-badge {
  visibility: hidden;
}

.before\:lm-absolute::before {
  content: var(--tw-content);
  position: absolute;
}

.before\:lm-left-\[18px\]::before {
  content: var(--tw-content);
  left: 18px;
}

.before\:lm-bottom-1::before {
  content: var(--tw-content);
  bottom: 0.25rem;
}

.before\:lm-mr-2::before {
  content: var(--tw-content);
  margin-right: 0.5rem;
}

.before\:lm-inline-block::before {
  content: var(--tw-content);
  display: inline-block;
}

.before\:lm-h-5::before {
  content: var(--tw-content);
  height: 1.25rem;
}

.before\:lm-w-5::before {
  content: var(--tw-content);
  width: 1.25rem;
}

.before\:lm-translate-x-\[18px\]::before {
  content: var(--tw-content);
  --tw-translate-x: 18px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:-lm-translate-x-\[14px\]::before {
  content: var(--tw-content);
  --tw-translate-x: -14px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:lm-rounded-\[18px\]::before {
  content: var(--tw-content);
  border-radius: 18px;
}

.before\:lm-rounded-card::before {
  content: var(--tw-content);
  border-radius: 1.25rem;
}

.before\:lm-bg-light-gray::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.before\:lm-bg-white::before {
  content: var(--tw-content);
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.before\:lm-py-1::before {
  content: var(--tw-content);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.before\:lm-px-4::before {
  content: var(--tw-content);
  padding-left: 1rem;
  padding-right: 1rem;
}

.before\:lm-transition-transform::before {
  content: var(--tw-content);
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
          transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
}

.before\:lm-duration-500::before {
  content: var(--tw-content);
  -webkit-transition-duration: 500ms;
          transition-duration: 500ms;
}

.before\:lm-content-\[\'On\'\]::before {
  --tw-content: 'On';
  content: var(--tw-content);
}

.before\:lm-content-\[\'Secured\'\]::before {
  --tw-content: 'Secured';
  content: var(--tw-content);
}

.before\:lm-content-\[\'Yes\'\]::before {
  --tw-content: 'Yes';
  content: var(--tw-content);
}

.after\:lm-absolute::after {
  content: var(--tw-content);
  position: absolute;
}

.after\:lm--left-\[15px\]::after {
  content: var(--tw-content);
  left: -15px;
}

.after\:lm-top-\[50px\]::after {
  content: var(--tw-content);
  top: 50px;
}

.after\:lm-left-0::after {
  content: var(--tw-content);
  left: 0px;
}

.after\:lm-top-\[20px\]::after {
  content: var(--tw-content);
  top: 20px;
}

.after\:lm-clear-both::after {
  content: var(--tw-content);
  clear: both;
}

.after\:lm-inline-block::after {
  content: var(--tw-content);
  display: inline-block;
}

.after\:lm-h-0::after {
  content: var(--tw-content);
  height: 0px;
}

.after\:lm-w-0::after {
  content: var(--tw-content);
  width: 0px;
}

.after\:lm-cursor-default::after {
  content: var(--tw-content);
  cursor: default;
}

.after\:lm-rounded-card::after {
  content: var(--tw-content);
  border-radius: 1.25rem;
}

.after\:lm-border-0::after {
  content: var(--tw-content);
  border-width: 0px;
}

.after\:lm-border-t-\[12px\]::after {
  content: var(--tw-content);
  border-top-width: 12px;
}

.after\:lm-border-b-\[12px\]::after {
  content: var(--tw-content);
  border-bottom-width: 12px;
}

.after\:lm-border-r-\[16px\]::after {
  content: var(--tw-content);
  border-right-width: 16px;
}

.after\:lm-border-t-\[6px\]::after {
  content: var(--tw-content);
  border-top-width: 6px;
}

.after\:lm-border-b-\[6px\]::after {
  content: var(--tw-content);
  border-bottom-width: 6px;
}

.after\:lm-border-r-\[12px\]::after {
  content: var(--tw-content);
  border-right-width: 12px;
}

.after\:lm-border-t-transparent::after {
  content: var(--tw-content);
  border-top-color: transparent;
}

.after\:lm-border-b-transparent::after {
  content: var(--tw-content);
  border-bottom-color: transparent;
}

.after\:lm-border-r-background::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-right-color: rgb(248 249 251 / var(--tw-border-opacity));
}

.after\:lm-border-r-white::after {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity));
}

.after\:lm-py-1::after {
  content: var(--tw-content);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.after\:lm-px-4::after {
  content: var(--tw-content);
  padding-left: 1rem;
  padding-right: 1rem;
}

.after\:lm-content-\[\'\'\]::after {
  --tw-content: '';
  content: var(--tw-content);
}

.after\:lm-content-\[\'Off\'\]::after {
  --tw-content: 'Off';
  content: var(--tw-content);
}

.after\:lm-content-\[\'Unsecured\'\]::after {
  --tw-content: 'Unsecured';
  content: var(--tw-content);
}

.after\:lm-content-\[\'No\'\]::after {
  --tw-content: 'No';
  content: var(--tw-content);
}

.first\:lm-rounded-l-md:first-child {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
}

.first\:lm-px-0:first-child {
  padding-left: 0px;
  padding-right: 0px;
}

.first\:lm-pt-0:first-child {
  padding-top: 0px;
}

.first\:lm-opacity-20:first-child {
  opacity: 0.2;
}

.last\:lm-rounded-r-md:last-child {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

.last\:lm-rounded-b-card:last-child {
  border-bottom-right-radius: 1.25rem;
  border-bottom-left-radius: 1.25rem;
}

.last\:lm-border-b-0:last-child {
  border-bottom-width: 0px;
}

.last\:lm-px-0:last-child {
  padding-left: 0px;
  padding-right: 0px;
}

.last\:lm-pb-5:last-child {
  padding-bottom: 1.25rem;
}

.odd\:lm-bg-gray-200:nth-child(odd) {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.focus-within\:lm-z-20:focus-within {
  z-index: 20;
}

.focus-within\:lm-border:focus-within {
  border-width: 1px;
}

.focus-within\:lm-border-base-pink:focus-within {
  --tw-border-opacity: 1;
  border-color: rgb(253 85 216 / var(--tw-border-opacity));
}

.hover\:lm-cursor-not-allowed:hover {
  cursor: not-allowed;
}

.hover\:lm-cursor-pointer:hover {
  cursor: pointer;
}

.hover\:lm-overflow-visible:hover {
  overflow: visible;
}

.hover\:lm-border-2:hover {
  border-width: 2px;
}

.hover\:\!lm-border-gray-primary:hover {
  --tw-border-opacity: 1 !important;
  border-color: rgb(93 114 135 / var(--tw-border-opacity)) !important;
}

.hover\:lm-border-yellow-500:hover {
  --tw-border-opacity: 1;
  border-color: rgb(236 201 75 / var(--tw-border-opacity));
}

.hover\:lm-border-base-red:hover {
  --tw-border-opacity: 1;
  border-color: rgb(223 66 128 / var(--tw-border-opacity));
}

.hover\:lm-border-purple-700:hover {
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
}

.hover\:lm-border-gray-primary:hover {
  --tw-border-opacity: 1;
  border-color: rgb(93 114 135 / var(--tw-border-opacity));
}

.hover\:lm-border-green-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(154 230 180 / var(--tw-border-opacity));
}

.hover\:lm-border-pink-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(255 166 236 / var(--tw-border-opacity));
}

.hover\:lm-border-gray-300:hover {
  --tw-border-opacity: 1;
  border-color: rgb(226 232 240 / var(--tw-border-opacity));
}

.hover\:lm-border-base-navy:hover {
  --tw-border-opacity: 1;
  border-color: rgb(0 35 75 / var(--tw-border-opacity));
}

.hover\:lm-border-base-purple:hover {
  --tw-border-opacity: 1;
  border-color: rgb(98 69 181 / var(--tw-border-opacity));
}

.hover\:\!lm-bg-white:hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.hover\:lm-bg-pink-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 212 246 / var(--tw-bg-opacity));
}

.hover\:lm-bg-gray-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.hover\:lm-bg-muted-blue:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(20 51 88 / var(--tw-bg-opacity));
}

.hover\:lm-bg-hover-pink:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(210 35 177 / var(--tw-bg-opacity));
}

.hover\:lm-bg-base-navy:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 35 75 / var(--tw-bg-opacity));
}

.hover\:lm-bg-kogan-dark-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(51 51 49 / var(--tw-bg-opacity));
}

.hover\:lm-bg-gray-300:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.hover\:lm-bg-gray-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.hover\:lm-bg-red-500:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(245 101 101 / var(--tw-bg-opacity));
}

.hover\:lm-bg-background:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.hover\:lm-bg-pink-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(228 34 186 / var(--tw-bg-opacity));
}

.hover\:lm-bg-purple-800:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(85 60 154 / var(--tw-bg-opacity));
}

.hover\:lm-bg-base-pink:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 85 216 / var(--tw-bg-opacity));
}

.hover\:lm-bg-base-purple:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(98 69 181 / var(--tw-bg-opacity));
}

.hover\:lm-bg-pink-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(253 236 250 / var(--tw-bg-opacity));
}

.hover\:lm-bg-purple-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(219 207 255 / var(--tw-bg-opacity));
}

.hover\:lm-bg-gray-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(93 114 135 / var(--tw-bg-opacity));
}

.hover\:lm-bg-green-200:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(198 246 213 / var(--tw-bg-opacity));
}

.hover\:lm-bg-green-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(244 254 251 / var(--tw-bg-opacity));
}

.hover\:lm-bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.hover\:lm-bg-red-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(255 244 244 / var(--tw-bg-opacity));
}

.hover\:lm-bg-base-red:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(223 66 128 / var(--tw-bg-opacity));
}

.hover\:lm-bg-light-gray:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.hover\:lm-bg-success:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(16 191 141 / var(--tw-bg-opacity));
}

.hover\:lm-bg-warning:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(238 146 26 / var(--tw-bg-opacity));
}

.hover\:lm-bg-subheading:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(143 166 170 / var(--tw-bg-opacity));
}

.hover\:lm-bg-green-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(24 164 124 / var(--tw-bg-opacity));
}

.hover\:lm-bg-gray-600:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(113 128 150 / var(--tw-bg-opacity));
}

.hover\:lm-bg-error:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(223 66 128 / var(--tw-bg-opacity));
}

.hover\:lm-bg-purple-100:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(240 235 255 / var(--tw-bg-opacity));
}

.hover\:lm-bg-purple-900:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(71 49 131 / var(--tw-bg-opacity));
}

.hover\:lm-bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:lm-fill-white:hover {
  fill: #FFFFFF;
}

.hover\:lm-fill-base-pink:hover {
  fill: #FD55D8;
}

.hover\:lm-fill-hover-pink:hover {
  fill: #D223B1;
}

.hover\:\!lm-text-gray-primary:hover {
  --tw-text-opacity: 1 !important;
  color: rgb(93 114 135 / var(--tw-text-opacity)) !important;
}

.hover\:lm-text-purple-900:hover {
  --tw-text-opacity: 1;
  color: rgb(71 49 131 / var(--tw-text-opacity));
}

.hover\:lm-text-white:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:lm-text-purple-700:hover {
  --tw-text-opacity: 1;
  color: rgb(98 69 181 / var(--tw-text-opacity));
}

.hover\:lm-text-base-yellow:hover {
  --tw-text-opacity: 1;
  color: rgb(255 188 59 / var(--tw-text-opacity));
}

.hover\:lm-text-hover-pink:hover {
  --tw-text-opacity: 1;
  color: rgb(210 35 177 / var(--tw-text-opacity));
}

.hover\:lm-text-base-navy:hover {
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
}

.hover\:lm-text-gray-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
}

.hover\:lm-text-pink-500:hover {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.hover\:lm-text-base-red:hover {
  --tw-text-opacity: 1;
  color: rgb(223 66 128 / var(--tw-text-opacity));
}

.hover\:lm-text-gray-500:hover {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
}

.hover\:lm-text-base-purple:hover {
  --tw-text-opacity: 1;
  color: rgb(98 69 181 / var(--tw-text-opacity));
}

.hover\:lm-text-base-blue:hover {
  --tw-text-opacity: 1;
  color: rgb(0 35 75 / var(--tw-text-opacity));
}

.hover\:lm-text-base-pink:hover {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.hover\:lm-text-kogan-dark-gray:hover {
  --tw-text-opacity: 1;
  color: rgb(51 51 49 / var(--tw-text-opacity));
}

.hover\:lm-text-icon-grey:hover {
  --tw-text-opacity: 1;
  color: rgb(72 101 134 / var(--tw-text-opacity));
}

.hover\:lm-text-red-100:hover {
  --tw-text-opacity: 1;
  color: rgb(255 244 244 / var(--tw-text-opacity));
}

.hover\:lm-text-red-500:hover {
  --tw-text-opacity: 1;
  color: rgb(245 101 101 / var(--tw-text-opacity));
}

.hover\:lm-text-pink-600:hover {
  --tw-text-opacity: 1;
  color: rgb(228 34 186 / var(--tw-text-opacity));
}

.hover\:lm-underline:hover {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.hover\:lm-opacity-80:hover {
  opacity: 0.8;
}

.hover\:lm-shadow-\[0_0_0_6px_\#EEF0F5\]:hover {
  --tw-shadow: 0 0 0 6px #EEF0F5;
  --tw-shadow-colored: 0 0 0 6px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.focus\:lm-border:focus {
  border-width: 1px;
}

.focus\:lm-border-kogan-dark-gray:focus {
  --tw-border-opacity: 1;
  border-color: rgb(51 51 49 / var(--tw-border-opacity));
}

.focus\:lm-border-gray-400:focus {
  --tw-border-opacity: 1;
  border-color: rgb(203 213 224 / var(--tw-border-opacity));
}

.focus\:lm-outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.active\:lm-border-2:active {
  border-width: 2px;
}

.active\:lm-border-4:active {
  border-width: 4px;
}

.active\:lm-border-yellow-500:active {
  --tw-border-opacity: 1;
  border-color: rgb(236 201 75 / var(--tw-border-opacity));
}

.active\:lm-border-base-yellow:active {
  --tw-border-opacity: 1;
  border-color: rgb(255 188 59 / var(--tw-border-opacity));
}

.active\:lm-bg-gray-300:active {
  --tw-bg-opacity: 1;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity));
}

.active\:lm-bg-gray-100:active {
  --tw-bg-opacity: 1;
  background-color: rgb(248 249 251 / var(--tw-bg-opacity));
}

.active\:lm-bg-red-500:active {
  --tw-bg-opacity: 1;
  background-color: rgb(245 101 101 / var(--tw-bg-opacity));
}

.active\:lm-bg-pink-600:active {
  --tw-bg-opacity: 1;
  background-color: rgb(228 34 186 / var(--tw-bg-opacity));
}

.active\:lm-bg-purple-800:active {
  --tw-bg-opacity: 1;
  background-color: rgb(85 60 154 / var(--tw-bg-opacity));
}

.active\:lm-bg-base-pink:active {
  --tw-bg-opacity: 1;
  background-color: rgb(253 85 216 / var(--tw-bg-opacity));
}

.active\:lm-bg-base-purple:active {
  --tw-bg-opacity: 1;
  background-color: rgb(98 69 181 / var(--tw-bg-opacity));
}

.active\:lm-text-gray-900:active {
  --tw-text-opacity: 1;
  color: rgb(26 32 44 / var(--tw-text-opacity));
}

.active\:lm-text-white:active {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.active\:lm-text-gray-800:active {
  --tw-text-opacity: 1;
  color: rgb(45 55 72 / var(--tw-text-opacity));
}

.active\:lm-shadow-button-active:active {
  --tw-shadow: 0px 1px 1px rgba(0, 35, 75, 0.04), 0px 1px 1px rgba(74, 85, 104, 0.21);
  --tw-shadow-colored: 0px 1px 1px var(--tw-shadow-color), 0px 1px 1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:lm-shadow-button-active-inset:active {
  --tw-shadow: inset 0px 1px 6px rgba(45, 55, 72, 0.15), inset 0px 2px 4px rgba(26, 32, 44, 0.14);
  --tw-shadow-colored: inset 0px 1px 6px var(--tw-shadow-color), inset 0px 2px 4px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
          box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.active\:lm-outline:active {
  outline-style: solid;
}

.active\:lm-outline-4:active {
  outline-width: 4px;
}

.active\:lm-outline-offset-\[-2px\]:active {
  outline-offset: -2px;
}

.active\:lm-outline-burnt-yellow-500:active {
  outline-color: #FFBC3B;
}

.disabled\:lm-cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:lm-cursor-default:disabled {
  cursor: default;
}

.disabled\:lm-bg-transparent:disabled {
  background-color: transparent;
}

.disabled\:lm-bg-gray-200:disabled {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.disabled\:lm-text-gray-500:disabled {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
}

.disabled\:lm-opacity-50:disabled {
  opacity: 0.5;
}

.disabled\:lm-opacity-60:disabled {
  opacity: 0.6;
}

.lm-group:hover .group-hover\:lm-border-gray-primary {
  --tw-border-opacity: 1;
  border-color: rgb(93 114 135 / var(--tw-border-opacity));
}

.lm-group:hover .group-hover\:lm-bg-gray-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(93 114 135 / var(--tw-bg-opacity));
}

.lm-group:hover .group-hover\:lm-bg-gray-200 {
  --tw-bg-opacity: 1;
  background-color: rgb(238 240 245 / var(--tw-bg-opacity));
}

.lm-group:hover .group-hover\:lm-fill-white {
  fill: #FFFFFF;
}

.lm-group:hover .group-hover\:lm-text-gray-primary {
  --tw-text-opacity: 1;
  color: rgb(93 114 135 / var(--tw-text-opacity));
}

.lm-group:hover .group-hover\:lm-text-pink-500 {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.lm-group:hover .group-hover\:lm-text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.lm-group:hover .group-hover\:lm-text-base-pink {
  --tw-text-opacity: 1;
  color: rgb(253 85 216 / var(--tw-text-opacity));
}

.lm-group:disabled .group-disabled\:lm-text-gray-500 {
  --tw-text-opacity: 1;
  color: rgb(160 174 192 / var(--tw-text-opacity));
}

@media (min-width: 640px) {
  .sm\:lm-left-1\/2 {
    left: 50%;
  }

  .sm\:lm-top-\[-142px\] {
    top: -142px;
  }

  .sm\:lm-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .sm\:lm-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .sm\:lm-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .sm\:lm-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .sm\:lm-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .sm\:lm-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .sm\:lm-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .sm\:lm-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .sm\:lm-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .sm\:lm-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .sm\:lm-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .sm\:lm-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .sm\:lm-col-span-full {
    grid-column: 1 / -1;
  }

  .sm\:lm-m-0 {
    margin: 0px;
  }

  .sm\:lm-m-1 {
    margin: 0.25rem;
  }

  .sm\:lm-m-2 {
    margin: 0.5rem;
  }

  .sm\:lm-m-3 {
    margin: 0.75rem;
  }

  .sm\:lm-m-4 {
    margin: 1rem;
  }

  .sm\:lm-m-5 {
    margin: 1.25rem;
  }

  .sm\:lm-m-6 {
    margin: 1.5rem;
  }

  .sm\:lm-m-8 {
    margin: 2rem;
  }

  .sm\:lm-m-10 {
    margin: 2.5rem;
  }

  .sm\:lm-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .sm\:lm-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .sm\:lm-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .sm\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .sm\:lm-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .sm\:lm-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:lm-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .sm\:lm-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .sm\:lm-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .sm\:lm-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .sm\:lm-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .sm\:lm-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .sm\:lm-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sm\:lm-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .sm\:lm-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .sm\:lm-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .sm\:lm-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .sm\:lm-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm\:lm-ml-2 {
    margin-left: 0.5rem;
  }

  .sm\:lm-ml-0 {
    margin-left: 0px;
  }

  .sm\:lm-ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:lm-ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:lm-ml-4 {
    margin-left: 1rem;
  }

  .sm\:lm-ml-5 {
    margin-left: 1.25rem;
  }

  .sm\:lm-ml-6 {
    margin-left: 1.5rem;
  }

  .sm\:lm-ml-8 {
    margin-left: 2rem;
  }

  .sm\:lm-ml-10 {
    margin-left: 2.5rem;
  }

  .sm\:lm-mr-0 {
    margin-right: 0px;
  }

  .sm\:lm-mr-1 {
    margin-right: 0.25rem;
  }

  .sm\:lm-mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:lm-mr-3 {
    margin-right: 0.75rem;
  }

  .sm\:lm-mr-4 {
    margin-right: 1rem;
  }

  .sm\:lm-mr-5 {
    margin-right: 1.25rem;
  }

  .sm\:lm-mr-6 {
    margin-right: 1.5rem;
  }

  .sm\:lm-mr-8 {
    margin-right: 2rem;
  }

  .sm\:lm-mr-10 {
    margin-right: 2.5rem;
  }

  .sm\:lm-mt-0 {
    margin-top: 0px;
  }

  .sm\:lm-mt-1 {
    margin-top: 0.25rem;
  }

  .sm\:lm-mt-2 {
    margin-top: 0.5rem;
  }

  .sm\:lm-mt-3 {
    margin-top: 0.75rem;
  }

  .sm\:lm-mt-4 {
    margin-top: 1rem;
  }

  .sm\:lm-mt-5 {
    margin-top: 1.25rem;
  }

  .sm\:lm-mt-6 {
    margin-top: 1.5rem;
  }

  .sm\:lm-mt-8 {
    margin-top: 2rem;
  }

  .sm\:lm-mt-10 {
    margin-top: 2.5rem;
  }

  .sm\:lm-mb-0 {
    margin-bottom: 0px;
  }

  .sm\:lm-mb-1 {
    margin-bottom: 0.25rem;
  }

  .sm\:lm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .sm\:lm-mb-3 {
    margin-bottom: 0.75rem;
  }

  .sm\:lm-mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:lm-mb-5 {
    margin-bottom: 1.25rem;
  }

  .sm\:lm-mb-6 {
    margin-bottom: 1.5rem;
  }

  .sm\:lm-mb-8 {
    margin-bottom: 2rem;
  }

  .sm\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .sm\:lm-mt-20 {
    margin-top: 5rem;
  }

  .sm\:lm-mr-20 {
    margin-right: 5rem;
  }

  .sm\:lm-block {
    display: block;
  }

  .sm\:lm-inline-block {
    display: inline-block;
  }

  .sm\:lm-inline {
    display: inline;
  }

  .sm\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .sm\:lm-grid {
    display: grid;
  }

  .sm\:lm-hidden {
    display: none;
  }

  .sm\:lm-h-\[200px\] {
    height: 200px;
  }

  .sm\:lm-w-12 {
    width: 3rem;
  }

  .sm\:lm-w-8 {
    width: 2rem;
  }

  .sm\:lm-w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .sm\:lm-w-3\/5 {
    width: 60%;
  }

  .sm\:lm-w-88 {
    width: 22rem;
  }

  .sm\:lm-w-\[95px\] {
    width: 95px;
  }

  .sm\:lm-w-full {
    width: 100%;
  }

  .sm\:lm-w-110 {
    width: 28rem;
  }

  .sm\:lm-w-56 {
    width: 14rem;
  }

  .sm\:lm-w-96 {
    width: 24rem;
  }

  .sm\:lm-w-118 {
    width: 30rem;
  }

  .sm\:lm-w-1\/2 {
    width: 50%;
  }

  .sm\:lm-w-\[500px\] {
    width: 500px;
  }

  .sm\:lm-min-w-24 {
    min-width: 6rem;
  }

  .sm\:lm-min-w-\[180px\] {
    min-width: 180px;
  }

  .sm\:lm-max-w-96 {
    max-width: 24rem;
  }

  .sm\:lm-max-w-70 {
    max-width: 15rem;
  }

  .sm\:lm-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .sm\:lm-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .sm\:lm-items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .sm\:lm-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .sm\:lm-items-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .sm\:lm-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .sm\:lm-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .sm\:lm-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .sm\:lm-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .sm\:lm-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .sm\:lm-justify-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .sm\:lm-justify-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .sm\:lm-gap-4 {
    gap: 1rem;
  }

  .sm\:lm-break-normal {
    overflow-wrap: normal;
    word-break: normal;
  }

  .sm\:lm-p-1 {
    padding: 0.25rem;
  }

  .sm\:lm-p-0 {
    padding: 0px;
  }

  .sm\:lm-p-2 {
    padding: 0.5rem;
  }

  .sm\:lm-p-3 {
    padding: 0.75rem;
  }

  .sm\:lm-p-4 {
    padding: 1rem;
  }

  .sm\:lm-p-5 {
    padding: 1.25rem;
  }

  .sm\:lm-p-6 {
    padding: 1.5rem;
  }

  .sm\:lm-p-8 {
    padding: 2rem;
  }

  .sm\:lm-p-10 {
    padding: 2.5rem;
  }

  .sm\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .sm\:lm-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .sm\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .sm\:lm-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .sm\:lm-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .sm\:lm-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .sm\:lm-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .sm\:lm-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:lm-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .sm\:lm-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .sm\:lm-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .sm\:lm-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .sm\:lm-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .sm\:lm-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .sm\:lm-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .sm\:lm-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .sm\:lm-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .sm\:lm-pt-20 {
    padding-top: 5rem;
  }

  .sm\:lm-pl-0 {
    padding-left: 0px;
  }

  .sm\:lm-pl-1 {
    padding-left: 0.25rem;
  }

  .sm\:lm-pl-2 {
    padding-left: 0.5rem;
  }

  .sm\:lm-pl-3 {
    padding-left: 0.75rem;
  }

  .sm\:lm-pl-4 {
    padding-left: 1rem;
  }

  .sm\:lm-pl-5 {
    padding-left: 1.25rem;
  }

  .sm\:lm-pl-6 {
    padding-left: 1.5rem;
  }

  .sm\:lm-pl-8 {
    padding-left: 2rem;
  }

  .sm\:lm-pl-10 {
    padding-left: 2.5rem;
  }

  .sm\:lm-pr-0 {
    padding-right: 0px;
  }

  .sm\:lm-pr-1 {
    padding-right: 0.25rem;
  }

  .sm\:lm-pr-2 {
    padding-right: 0.5rem;
  }

  .sm\:lm-pr-3 {
    padding-right: 0.75rem;
  }

  .sm\:lm-pr-4 {
    padding-right: 1rem;
  }

  .sm\:lm-pr-5 {
    padding-right: 1.25rem;
  }

  .sm\:lm-pr-6 {
    padding-right: 1.5rem;
  }

  .sm\:lm-pr-8 {
    padding-right: 2rem;
  }

  .sm\:lm-pr-10 {
    padding-right: 2.5rem;
  }

  .sm\:lm-pt-0 {
    padding-top: 0px;
  }

  .sm\:lm-pt-1 {
    padding-top: 0.25rem;
  }

  .sm\:lm-pt-2 {
    padding-top: 0.5rem;
  }

  .sm\:lm-pt-3 {
    padding-top: 0.75rem;
  }

  .sm\:lm-pt-4 {
    padding-top: 1rem;
  }

  .sm\:lm-pt-5 {
    padding-top: 1.25rem;
  }

  .sm\:lm-pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:lm-pt-8 {
    padding-top: 2rem;
  }

  .sm\:lm-pt-10 {
    padding-top: 2.5rem;
  }

  .sm\:lm-pb-0 {
    padding-bottom: 0px;
  }

  .sm\:lm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .sm\:lm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .sm\:lm-pb-3 {
    padding-bottom: 0.75rem;
  }

  .sm\:lm-pb-4 {
    padding-bottom: 1rem;
  }

  .sm\:lm-pb-5 {
    padding-bottom: 1.25rem;
  }

  .sm\:lm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .sm\:lm-pb-8 {
    padding-bottom: 2rem;
  }

  .sm\:lm-pb-10 {
    padding-bottom: 2.5rem;
  }

  .sm\:lm-text-xs {
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .sm\:lm-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .sm\:lm-text-heading-one {
    font-size: 2.625rem;
  }

  .sm\:lm-text-heading-two {
    font-size: 2.25rem;
  }

  .sm\:lm-text-heading-three {
    font-size: 2rem;
  }

  .sm\:lm-text-heading-four {
    font-size: 1.625rem;
  }

  .sm\:lm-text-heading-five {
    font-size: 1.25rem;
  }

  .sm\:lm-text-heading-six {
    font-size: 1rem;
  }

  .sm\:lm-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .sm\:lm-leading-12 {
    line-height: 3rem;
  }

  .sm\:lm-leading-heading-one {
    line-height: 2.625rem;
  }

  .sm\:lm-leading-heading-two {
    line-height: 2.25rem;
  }

  .sm\:lm-leading-heading-three {
    line-height: 2.25rem;
  }

  .sm\:lm-leading-heading-four {
    line-height: 2rem;
  }

  .sm\:lm-leading-heading-five {
    line-height: 1.5rem;
  }

  .sm\:lm-leading-heading-six {
    line-height: 1.5rem;
  }

  .sm\:lm-stack-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .sm\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0;
  }

  .lm-stack-xs.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .lm-stack-s.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  .lm-stack-m.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.875rem;
    margin-bottom: 0;
  }

  .lm-stack-xm.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .lm-stack-r.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .sm\:lm-stack-l.lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .lm-stack-l.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .sm\:lm-stack-l.lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-l.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.sm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .lm-stack-xl.sm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (min-width: 768px) {
  .md\:lm-static {
    position: static;
  }

  .md\:lm-fixed {
    position: fixed;
  }

  .md\:lm-order-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }

  .md\:lm-order-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }

  .md\:lm-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .md\:lm-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .md\:lm-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .md\:lm-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .md\:lm-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .md\:lm-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .md\:lm-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .md\:lm-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .md\:lm-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .md\:lm-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .md\:lm-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .md\:lm-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .md\:lm-col-span-full {
    grid-column: 1 / -1;
  }

  .md\:lm-col-start-4 {
    grid-column-start: 4;
  }

  .md\:lm-col-start-1 {
    grid-column-start: 1;
  }

  .md\:lm-col-start-3 {
    grid-column-start: 3;
  }

  .md\:lm-m-0 {
    margin: 0px;
  }

  .md\:lm-m-1 {
    margin: 0.25rem;
  }

  .md\:lm-m-2 {
    margin: 0.5rem;
  }

  .md\:lm-m-3 {
    margin: 0.75rem;
  }

  .md\:lm-m-4 {
    margin: 1rem;
  }

  .md\:lm-m-5 {
    margin: 1.25rem;
  }

  .md\:lm-m-6 {
    margin: 1.5rem;
  }

  .md\:lm-m-8 {
    margin: 2rem;
  }

  .md\:lm-m-10 {
    margin: 2.5rem;
  }

  .md\:lm-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .md\:lm-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .md\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .md\:lm-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .md\:lm-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .md\:lm-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .md\:lm-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .md\:lm-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .md\:lm-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .md\:lm-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .md\:lm-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .md\:lm-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .md\:lm-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .md\:lm-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .md\:lm-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .md\:lm-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .md\:lm-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:lm-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .md\:lm-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .md\:lm-ml-12 {
    margin-left: 3rem;
  }

  .md\:lm-mt-8 {
    margin-top: 2rem;
  }

  .md\:lm-ml-0 {
    margin-left: 0px;
  }

  .md\:lm-ml-1 {
    margin-left: 0.25rem;
  }

  .md\:lm-ml-2 {
    margin-left: 0.5rem;
  }

  .md\:lm-ml-3 {
    margin-left: 0.75rem;
  }

  .md\:lm-ml-4 {
    margin-left: 1rem;
  }

  .md\:lm-ml-5 {
    margin-left: 1.25rem;
  }

  .md\:lm-ml-6 {
    margin-left: 1.5rem;
  }

  .md\:lm-ml-8 {
    margin-left: 2rem;
  }

  .md\:lm-ml-10 {
    margin-left: 2.5rem;
  }

  .md\:lm-mr-0 {
    margin-right: 0px;
  }

  .md\:lm-mr-1 {
    margin-right: 0.25rem;
  }

  .md\:lm-mr-2 {
    margin-right: 0.5rem;
  }

  .md\:lm-mr-3 {
    margin-right: 0.75rem;
  }

  .md\:lm-mr-4 {
    margin-right: 1rem;
  }

  .md\:lm-mr-5 {
    margin-right: 1.25rem;
  }

  .md\:lm-mr-6 {
    margin-right: 1.5rem;
  }

  .md\:lm-mr-8 {
    margin-right: 2rem;
  }

  .md\:lm-mr-10 {
    margin-right: 2.5rem;
  }

  .md\:lm-mt-0 {
    margin-top: 0px;
  }

  .md\:lm-mt-1 {
    margin-top: 0.25rem;
  }

  .md\:lm-mt-2 {
    margin-top: 0.5rem;
  }

  .md\:lm-mt-3 {
    margin-top: 0.75rem;
  }

  .md\:lm-mt-4 {
    margin-top: 1rem;
  }

  .md\:lm-mt-5 {
    margin-top: 1.25rem;
  }

  .md\:lm-mt-6 {
    margin-top: 1.5rem;
  }

  .md\:lm-mt-10 {
    margin-top: 2.5rem;
  }

  .md\:lm-mb-0 {
    margin-bottom: 0px;
  }

  .md\:lm-mb-1 {
    margin-bottom: 0.25rem;
  }

  .md\:lm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .md\:lm-mb-3 {
    margin-bottom: 0.75rem;
  }

  .md\:lm-mb-4 {
    margin-bottom: 1rem;
  }

  .md\:lm-mb-5 {
    margin-bottom: 1.25rem;
  }

  .md\:lm-mb-6 {
    margin-bottom: 1.5rem;
  }

  .md\:lm-mb-8 {
    margin-bottom: 2rem;
  }

  .md\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .md\:lm-mt-12 {
    margin-top: 3rem;
  }

  .md\:lm-mb-24 {
    margin-bottom: 6rem;
  }

  .md\:lm-mt-20 {
    margin-top: 5rem;
  }

  .md\:lm-block {
    display: block;
  }

  .md\:lm-inline-block {
    display: inline-block;
  }

  .md\:lm-inline {
    display: inline;
  }

  .md\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .md\:lm-grid {
    display: grid;
  }

  .md\:lm-hidden {
    display: none;
  }

  .md\:lm-h-12 {
    height: 3rem;
  }

  .md\:lm-h-header-nav {
    height: 90px;
  }

  .md\:lm-h-full {
    height: 100%;
  }

  .md\:lm-h-auto {
    height: auto;
  }

  .md\:lm-h-screen {
    height: 100vh;
  }

  .md\:lm-w-auto {
    width: auto;
  }

  .md\:lm-w-fit {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }

  .md\:lm-w-12 {
    width: 3rem;
  }

  .md\:lm-w-full {
    width: 100%;
  }

  .md\:lm-w-11\/12 {
    width: 91.666667%;
  }

  .md\:lm-w-\[180px\] {
    width: 180px;
  }

  .md\:lm-w-1\/2 {
    width: 50%;
  }

  .md\:lm-w-5\/12 {
    width: 41.666667%;
  }

  .md\:lm-w-4\/12 {
    width: 33.333333%;
  }

  .md\:lm-w-\[774px\] {
    width: 774px;
  }

  .md\:lm-w-6\/12 {
    width: 50%;
  }

  .md\:lm-w-10\/12 {
    width: 83.333333%;
  }

  .md\:lm-min-w-24 {
    min-width: 6rem;
  }

  .md\:lm-min-w-\[420px\] {
    min-width: 420px;
  }

  .md\:lm-max-w-sm {
    max-width: 24rem;
  }

  .md\:lm-grid-cols-fixed12 {
    grid-template-columns: repeat(12, 55px);
  }

  .md\:lm-grid-cols-fixed18 {
    grid-template-columns: repeat(18, 55px);
  }

  .md\:lm-grid-cols-responsive12 {
    grid-template-columns: repeat(12, 1fr);
  }

  .md\:lm-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:lm-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .md\:lm-flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .md\:lm-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .md\:lm-items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .md\:lm-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .md\:lm-items-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .md\:lm-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .md\:lm-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .md\:lm-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .md\:lm-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .md\:lm-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .md\:lm-justify-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .md\:lm-justify-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .md\:lm-gap-3 {
    gap: 0.75rem;
  }

  .md\:lm-gap-7\.5 {
    gap: 1.875rem;
  }

  .md\:lm-gap-7 {
    gap: 1.75rem;
  }

  .md\:lm-gap-10 {
    gap: 2.5rem;
  }

  .md\:lm-gap-x-10 {
    -webkit-column-gap: 2.5rem;
       -moz-column-gap: 2.5rem;
            column-gap: 2.5rem;
  }

  .md\:lm-gap-y-5 {
    row-gap: 1.25rem;
  }

  .md\:lm-space-x-16 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(4rem * var(--tw-space-x-reverse));
    margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse)));
  }

  .md\:lm-self-end {
    -ms-flex-item-align: end;
        align-self: flex-end;
  }

  .md\:lm-rounded-r-card {
    border-top-right-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
  }

  .md\:lm-rounded-r-inner-card {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  .md\:lm-rounded-b-none {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .md\:lm-p-0 {
    padding: 0px;
  }

  .md\:lm-p-1 {
    padding: 0.25rem;
  }

  .md\:lm-p-2 {
    padding: 0.5rem;
  }

  .md\:lm-p-3 {
    padding: 0.75rem;
  }

  .md\:lm-p-4 {
    padding: 1rem;
  }

  .md\:lm-p-5 {
    padding: 1.25rem;
  }

  .md\:lm-p-6 {
    padding: 1.5rem;
  }

  .md\:lm-p-8 {
    padding: 2rem;
  }

  .md\:lm-p-10 {
    padding: 2.5rem;
  }

  .md\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .md\:lm-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .md\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .md\:lm-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .md\:lm-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:lm-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .md\:lm-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:lm-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:lm-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .md\:lm-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .md\:lm-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .md\:lm-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .md\:lm-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .md\:lm-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .md\:lm-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .md\:lm-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:lm-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .md\:lm-px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }

  .md\:lm-px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:lm-px-\[70px\] {
    padding-left: 70px;
    padding-right: 70px;
  }

  .md\:lm-pl-0 {
    padding-left: 0px;
  }

  .md\:lm-pl-1 {
    padding-left: 0.25rem;
  }

  .md\:lm-pl-2 {
    padding-left: 0.5rem;
  }

  .md\:lm-pl-3 {
    padding-left: 0.75rem;
  }

  .md\:lm-pl-4 {
    padding-left: 1rem;
  }

  .md\:lm-pl-5 {
    padding-left: 1.25rem;
  }

  .md\:lm-pl-6 {
    padding-left: 1.5rem;
  }

  .md\:lm-pl-8 {
    padding-left: 2rem;
  }

  .md\:lm-pl-10 {
    padding-left: 2.5rem;
  }

  .md\:lm-pr-0 {
    padding-right: 0px;
  }

  .md\:lm-pr-1 {
    padding-right: 0.25rem;
  }

  .md\:lm-pr-2 {
    padding-right: 0.5rem;
  }

  .md\:lm-pr-3 {
    padding-right: 0.75rem;
  }

  .md\:lm-pr-4 {
    padding-right: 1rem;
  }

  .md\:lm-pr-5 {
    padding-right: 1.25rem;
  }

  .md\:lm-pr-6 {
    padding-right: 1.5rem;
  }

  .md\:lm-pr-8 {
    padding-right: 2rem;
  }

  .md\:lm-pr-10 {
    padding-right: 2.5rem;
  }

  .md\:lm-pt-0 {
    padding-top: 0px;
  }

  .md\:lm-pt-1 {
    padding-top: 0.25rem;
  }

  .md\:lm-pt-2 {
    padding-top: 0.5rem;
  }

  .md\:lm-pt-3 {
    padding-top: 0.75rem;
  }

  .md\:lm-pt-4 {
    padding-top: 1rem;
  }

  .md\:lm-pt-5 {
    padding-top: 1.25rem;
  }

  .md\:lm-pt-6 {
    padding-top: 1.5rem;
  }

  .md\:lm-pt-8 {
    padding-top: 2rem;
  }

  .md\:lm-pt-10 {
    padding-top: 2.5rem;
  }

  .md\:lm-pb-0 {
    padding-bottom: 0px;
  }

  .md\:lm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .md\:lm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:lm-pb-3 {
    padding-bottom: 0.75rem;
  }

  .md\:lm-pb-4 {
    padding-bottom: 1rem;
  }

  .md\:lm-pb-5 {
    padding-bottom: 1.25rem;
  }

  .md\:lm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .md\:lm-pb-8 {
    padding-bottom: 2rem;
  }

  .md\:lm-pb-10 {
    padding-bottom: 2.5rem;
  }

  .md\:lm-text-left {
    text-align: left;
  }

  .md\:lm-text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:lm-text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:lm-text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:lm-leading-7 {
    line-height: 1.75rem;
  }

  .md\:lm-stack-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .md\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0;
  }

  .lm-stack-xs.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .lm-stack-s.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  .lm-stack-m.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.875rem;
    margin-bottom: 0;
  }

  .lm-stack-xm.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .lm-stack-r.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .lm-stack-l.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .lm-stack-l.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.md\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .lm-stack-xl.md\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (min-width: 1024px) {
  .lg\:lm-relative {
    position: relative;
  }

  .lg\:lm-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .lg\:lm-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .lg\:lm-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .lg\:lm-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .lg\:lm-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .lg\:lm-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .lg\:lm-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .lg\:lm-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .lg\:lm-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .lg\:lm-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .lg\:lm-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .lg\:lm-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .lg\:lm-col-span-full {
    grid-column: 1 / -1;
  }

  .lg\:lm-col-start-4 {
    grid-column-start: 4;
  }

  .lg\:lm-m-0 {
    margin: 0px;
  }

  .lg\:lm-m-1 {
    margin: 0.25rem;
  }

  .lg\:lm-m-2 {
    margin: 0.5rem;
  }

  .lg\:lm-m-3 {
    margin: 0.75rem;
  }

  .lg\:lm-m-4 {
    margin: 1rem;
  }

  .lg\:lm-m-5 {
    margin: 1.25rem;
  }

  .lg\:lm-m-6 {
    margin: 1.5rem;
  }

  .lg\:lm-m-8 {
    margin: 2rem;
  }

  .lg\:lm-m-10 {
    margin: 2.5rem;
  }

  .lg\:lm-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .lg\:lm-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .lg\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .lg\:lm-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .lg\:lm-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .lg\:lm-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .lg\:lm-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .lg\:lm-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .lg\:lm-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .lg\:lm-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .lg\:lm-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .lg\:lm-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .lg\:lm-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .lg\:lm-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .lg\:lm-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .lg\:lm-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .lg\:lm-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .lg\:lm-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:lm-mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .lg\:lm-mt-56 {
    margin-top: 14rem;
  }

  .lg\:lm-ml-0 {
    margin-left: 0px;
  }

  .lg\:lm-ml-1 {
    margin-left: 0.25rem;
  }

  .lg\:lm-ml-2 {
    margin-left: 0.5rem;
  }

  .lg\:lm-ml-3 {
    margin-left: 0.75rem;
  }

  .lg\:lm-ml-4 {
    margin-left: 1rem;
  }

  .lg\:lm-ml-5 {
    margin-left: 1.25rem;
  }

  .lg\:lm-ml-6 {
    margin-left: 1.5rem;
  }

  .lg\:lm-ml-8 {
    margin-left: 2rem;
  }

  .lg\:lm-ml-10 {
    margin-left: 2.5rem;
  }

  .lg\:lm-mr-0 {
    margin-right: 0px;
  }

  .lg\:lm-mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:lm-mr-2 {
    margin-right: 0.5rem;
  }

  .lg\:lm-mr-3 {
    margin-right: 0.75rem;
  }

  .lg\:lm-mr-4 {
    margin-right: 1rem;
  }

  .lg\:lm-mr-5 {
    margin-right: 1.25rem;
  }

  .lg\:lm-mr-6 {
    margin-right: 1.5rem;
  }

  .lg\:lm-mr-8 {
    margin-right: 2rem;
  }

  .lg\:lm-mr-10 {
    margin-right: 2.5rem;
  }

  .lg\:lm-mt-0 {
    margin-top: 0px;
  }

  .lg\:lm-mt-1 {
    margin-top: 0.25rem;
  }

  .lg\:lm-mt-2 {
    margin-top: 0.5rem;
  }

  .lg\:lm-mt-3 {
    margin-top: 0.75rem;
  }

  .lg\:lm-mt-4 {
    margin-top: 1rem;
  }

  .lg\:lm-mt-5 {
    margin-top: 1.25rem;
  }

  .lg\:lm-mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:lm-mt-8 {
    margin-top: 2rem;
  }

  .lg\:lm-mt-10 {
    margin-top: 2.5rem;
  }

  .lg\:lm-mb-0 {
    margin-bottom: 0px;
  }

  .lg\:lm-mb-1 {
    margin-bottom: 0.25rem;
  }

  .lg\:lm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .lg\:lm-mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:lm-mb-4 {
    margin-bottom: 1rem;
  }

  .lg\:lm-mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:lm-mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:lm-mb-8 {
    margin-bottom: 2rem;
  }

  .lg\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .lg\:lm-block {
    display: block;
  }

  .lg\:lm-inline-block {
    display: inline-block;
  }

  .lg\:lm-inline {
    display: inline;
  }

  .lg\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .lg\:lm-grid {
    display: grid;
  }

  .lg\:lm-hidden {
    display: none;
  }

  .lg\:lm-h-screen {
    height: 100vh;
  }

  .lg\:lm-h-\[220px\] {
    height: 220px;
  }

  .lg\:lm-w-32 {
    width: 8rem;
  }

  .lg\:lm-w-8\/12 {
    width: 66.666667%;
  }

  .lg\:lm-max-w-118 {
    max-width: 30rem;
  }

  .lg\:lm-grid-flow-row-dense {
    grid-auto-flow: row dense;
  }

  .lg\:lm-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .lg\:lm-flex-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
  }

  .lg\:lm-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .lg\:lm-items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .lg\:lm-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .lg\:lm-items-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .lg\:lm-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .lg\:lm-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .lg\:lm-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .lg\:lm-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .lg\:lm-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .lg\:lm-justify-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .lg\:lm-justify-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .lg\:lm-gap-15 {
    gap: 3.75rem;
  }

  .lg\:lm-gap-8 {
    gap: 2rem;
  }

  .lg\:lm-gap-5 {
    gap: 1.25rem;
  }

  .lg\:lm-gap-10 {
    gap: 2.5rem;
  }

  .lg\:lm-p-0 {
    padding: 0px;
  }

  .lg\:lm-p-1 {
    padding: 0.25rem;
  }

  .lg\:lm-p-2 {
    padding: 0.5rem;
  }

  .lg\:lm-p-3 {
    padding: 0.75rem;
  }

  .lg\:lm-p-4 {
    padding: 1rem;
  }

  .lg\:lm-p-5 {
    padding: 1.25rem;
  }

  .lg\:lm-p-6 {
    padding: 1.5rem;
  }

  .lg\:lm-p-8 {
    padding: 2rem;
  }

  .lg\:lm-p-10 {
    padding: 2.5rem;
  }

  .lg\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:lm-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .lg\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .lg\:lm-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .lg\:lm-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:lm-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:lm-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:lm-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:lm-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:lm-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .lg\:lm-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:lm-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .lg\:lm-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .lg\:lm-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .lg\:lm-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:lm-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .lg\:lm-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .lg\:lm-pb-36 {
    padding-bottom: 9rem;
  }

  .lg\:lm-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:lm-pl-0 {
    padding-left: 0px;
  }

  .lg\:lm-pl-1 {
    padding-left: 0.25rem;
  }

  .lg\:lm-pl-2 {
    padding-left: 0.5rem;
  }

  .lg\:lm-pl-3 {
    padding-left: 0.75rem;
  }

  .lg\:lm-pl-4 {
    padding-left: 1rem;
  }

  .lg\:lm-pl-5 {
    padding-left: 1.25rem;
  }

  .lg\:lm-pl-6 {
    padding-left: 1.5rem;
  }

  .lg\:lm-pl-8 {
    padding-left: 2rem;
  }

  .lg\:lm-pl-10 {
    padding-left: 2.5rem;
  }

  .lg\:lm-pr-0 {
    padding-right: 0px;
  }

  .lg\:lm-pr-1 {
    padding-right: 0.25rem;
  }

  .lg\:lm-pr-2 {
    padding-right: 0.5rem;
  }

  .lg\:lm-pr-3 {
    padding-right: 0.75rem;
  }

  .lg\:lm-pr-4 {
    padding-right: 1rem;
  }

  .lg\:lm-pr-5 {
    padding-right: 1.25rem;
  }

  .lg\:lm-pr-6 {
    padding-right: 1.5rem;
  }

  .lg\:lm-pr-8 {
    padding-right: 2rem;
  }

  .lg\:lm-pr-10 {
    padding-right: 2.5rem;
  }

  .lg\:lm-pt-0 {
    padding-top: 0px;
  }

  .lg\:lm-pt-1 {
    padding-top: 0.25rem;
  }

  .lg\:lm-pt-2 {
    padding-top: 0.5rem;
  }

  .lg\:lm-pt-3 {
    padding-top: 0.75rem;
  }

  .lg\:lm-pt-4 {
    padding-top: 1rem;
  }

  .lg\:lm-pt-5 {
    padding-top: 1.25rem;
  }

  .lg\:lm-pt-6 {
    padding-top: 1.5rem;
  }

  .lg\:lm-pt-8 {
    padding-top: 2rem;
  }

  .lg\:lm-pb-0 {
    padding-bottom: 0px;
  }

  .lg\:lm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .lg\:lm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .lg\:lm-pb-3 {
    padding-bottom: 0.75rem;
  }

  .lg\:lm-pb-4 {
    padding-bottom: 1rem;
  }

  .lg\:lm-pb-5 {
    padding-bottom: 1.25rem;
  }

  .lg\:lm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .lg\:lm-pb-8 {
    padding-bottom: 2rem;
  }

  .lg\:lm-pb-10 {
    padding-bottom: 2.5rem;
  }

  .lg\:lm-shadow-300 {
    --tw-shadow: 0px 20px 50px rgba(107, 113, 125, 0.25);
    --tw-shadow-colored: 0px 20px 50px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
            box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:lm-stack-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .lg\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0;
  }

  .lm-stack-xs.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .lm-stack-s.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  .lm-stack-m.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.875rem;
    margin-bottom: 0;
  }

  .lm-stack-xm.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .lm-stack-r.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .lm-stack-l.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .lm-stack-l.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.lg\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .lm-stack-xl.lg\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (min-width: 1280px) {
  .xl\:lm-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xl\:lm-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xl\:lm-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xl\:lm-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xl\:lm-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xl\:lm-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xl\:lm-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xl\:lm-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xl\:lm-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xl\:lm-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xl\:lm-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xl\:lm-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xl\:lm-col-span-full {
    grid-column: 1 / -1;
  }

  .xl\:lm-m-0 {
    margin: 0px;
  }

  .xl\:lm-m-1 {
    margin: 0.25rem;
  }

  .xl\:lm-m-2 {
    margin: 0.5rem;
  }

  .xl\:lm-m-3 {
    margin: 0.75rem;
  }

  .xl\:lm-m-4 {
    margin: 1rem;
  }

  .xl\:lm-m-5 {
    margin: 1.25rem;
  }

  .xl\:lm-m-6 {
    margin: 1.5rem;
  }

  .xl\:lm-m-8 {
    margin: 2rem;
  }

  .xl\:lm-m-10 {
    margin: 2.5rem;
  }

  .xl\:lm-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .xl\:lm-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xl\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xl\:lm-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xl\:lm-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xl\:lm-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xl\:lm-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:lm-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xl\:lm-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xl\:lm-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .xl\:lm-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xl\:lm-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xl\:lm-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xl\:lm-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xl\:lm-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xl\:lm-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xl\:lm-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xl\:lm-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xl\:lm-ml-0 {
    margin-left: 0px;
  }

  .xl\:lm-ml-1 {
    margin-left: 0.25rem;
  }

  .xl\:lm-ml-2 {
    margin-left: 0.5rem;
  }

  .xl\:lm-ml-3 {
    margin-left: 0.75rem;
  }

  .xl\:lm-ml-4 {
    margin-left: 1rem;
  }

  .xl\:lm-ml-5 {
    margin-left: 1.25rem;
  }

  .xl\:lm-ml-6 {
    margin-left: 1.5rem;
  }

  .xl\:lm-ml-8 {
    margin-left: 2rem;
  }

  .xl\:lm-ml-10 {
    margin-left: 2.5rem;
  }

  .xl\:lm-mr-0 {
    margin-right: 0px;
  }

  .xl\:lm-mr-1 {
    margin-right: 0.25rem;
  }

  .xl\:lm-mr-2 {
    margin-right: 0.5rem;
  }

  .xl\:lm-mr-3 {
    margin-right: 0.75rem;
  }

  .xl\:lm-mr-4 {
    margin-right: 1rem;
  }

  .xl\:lm-mr-5 {
    margin-right: 1.25rem;
  }

  .xl\:lm-mr-6 {
    margin-right: 1.5rem;
  }

  .xl\:lm-mr-8 {
    margin-right: 2rem;
  }

  .xl\:lm-mr-10 {
    margin-right: 2.5rem;
  }

  .xl\:lm-mt-0 {
    margin-top: 0px;
  }

  .xl\:lm-mt-1 {
    margin-top: 0.25rem;
  }

  .xl\:lm-mt-2 {
    margin-top: 0.5rem;
  }

  .xl\:lm-mt-3 {
    margin-top: 0.75rem;
  }

  .xl\:lm-mt-4 {
    margin-top: 1rem;
  }

  .xl\:lm-mt-5 {
    margin-top: 1.25rem;
  }

  .xl\:lm-mt-6 {
    margin-top: 1.5rem;
  }

  .xl\:lm-mt-8 {
    margin-top: 2rem;
  }

  .xl\:lm-mt-10 {
    margin-top: 2.5rem;
  }

  .xl\:lm-mb-0 {
    margin-bottom: 0px;
  }

  .xl\:lm-mb-1 {
    margin-bottom: 0.25rem;
  }

  .xl\:lm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xl\:lm-mb-3 {
    margin-bottom: 0.75rem;
  }

  .xl\:lm-mb-4 {
    margin-bottom: 1rem;
  }

  .xl\:lm-mb-5 {
    margin-bottom: 1.25rem;
  }

  .xl\:lm-mb-6 {
    margin-bottom: 1.5rem;
  }

  .xl\:lm-mb-8 {
    margin-bottom: 2rem;
  }

  .xl\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .xl\:lm-block {
    display: block;
  }

  .xl\:lm-inline-block {
    display: inline-block;
  }

  .xl\:lm-inline {
    display: inline;
  }

  .xl\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .xl\:lm-grid {
    display: grid;
  }

  .xl\:lm-hidden {
    display: none;
  }

  .xl\:lm-h-\[200px\] {
    height: 200px;
  }

  .xl\:lm-w-60 {
    width: 15rem;
  }

  .xl\:lm-w-\[200px\] {
    width: 200px;
  }

  .xl\:lm-w-3\/12 {
    width: 25%;
  }

  .xl\:lm-max-w-88 {
    max-width: 22rem;
  }

  .xl\:lm-grid-flow-col {
    grid-auto-flow: column;
  }

  .xl\:lm-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .xl\:lm-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }

  .xl\:lm-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .xl\:lm-flex-col {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .xl\:lm-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .xl\:lm-items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .xl\:lm-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .xl\:lm-items-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .xl\:lm-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .xl\:lm-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .xl\:lm-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .xl\:lm-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .xl\:lm-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .xl\:lm-justify-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .xl\:lm-justify-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .xl\:lm-p-0 {
    padding: 0px;
  }

  .xl\:lm-p-1 {
    padding: 0.25rem;
  }

  .xl\:lm-p-2 {
    padding: 0.5rem;
  }

  .xl\:lm-p-3 {
    padding: 0.75rem;
  }

  .xl\:lm-p-4 {
    padding: 1rem;
  }

  .xl\:lm-p-5 {
    padding: 1.25rem;
  }

  .xl\:lm-p-6 {
    padding: 1.5rem;
  }

  .xl\:lm-p-8 {
    padding: 2rem;
  }

  .xl\:lm-p-10 {
    padding: 2.5rem;
  }

  .xl\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xl\:lm-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xl\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xl\:lm-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xl\:lm-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xl\:lm-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:lm-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:lm-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xl\:lm-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .xl\:lm-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xl\:lm-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xl\:lm-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xl\:lm-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xl\:lm-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xl\:lm-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xl\:lm-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:lm-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xl\:lm-px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .xl\:lm-pl-0 {
    padding-left: 0px;
  }

  .xl\:lm-pl-1 {
    padding-left: 0.25rem;
  }

  .xl\:lm-pl-2 {
    padding-left: 0.5rem;
  }

  .xl\:lm-pl-3 {
    padding-left: 0.75rem;
  }

  .xl\:lm-pl-4 {
    padding-left: 1rem;
  }

  .xl\:lm-pl-5 {
    padding-left: 1.25rem;
  }

  .xl\:lm-pl-6 {
    padding-left: 1.5rem;
  }

  .xl\:lm-pl-8 {
    padding-left: 2rem;
  }

  .xl\:lm-pl-10 {
    padding-left: 2.5rem;
  }

  .xl\:lm-pr-0 {
    padding-right: 0px;
  }

  .xl\:lm-pr-1 {
    padding-right: 0.25rem;
  }

  .xl\:lm-pr-2 {
    padding-right: 0.5rem;
  }

  .xl\:lm-pr-3 {
    padding-right: 0.75rem;
  }

  .xl\:lm-pr-4 {
    padding-right: 1rem;
  }

  .xl\:lm-pr-5 {
    padding-right: 1.25rem;
  }

  .xl\:lm-pr-6 {
    padding-right: 1.5rem;
  }

  .xl\:lm-pr-8 {
    padding-right: 2rem;
  }

  .xl\:lm-pr-10 {
    padding-right: 2.5rem;
  }

  .xl\:lm-pt-0 {
    padding-top: 0px;
  }

  .xl\:lm-pt-1 {
    padding-top: 0.25rem;
  }

  .xl\:lm-pt-2 {
    padding-top: 0.5rem;
  }

  .xl\:lm-pt-3 {
    padding-top: 0.75rem;
  }

  .xl\:lm-pt-4 {
    padding-top: 1rem;
  }

  .xl\:lm-pt-5 {
    padding-top: 1.25rem;
  }

  .xl\:lm-pt-6 {
    padding-top: 1.5rem;
  }

  .xl\:lm-pt-8 {
    padding-top: 2rem;
  }

  .xl\:lm-pt-10 {
    padding-top: 2.5rem;
  }

  .xl\:lm-pb-0 {
    padding-bottom: 0px;
  }

  .xl\:lm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .xl\:lm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .xl\:lm-pb-3 {
    padding-bottom: 0.75rem;
  }

  .xl\:lm-pb-4 {
    padding-bottom: 1rem;
  }

  .xl\:lm-pb-5 {
    padding-bottom: 1.25rem;
  }

  .xl\:lm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .xl\:lm-pb-8 {
    padding-bottom: 2rem;
  }

  .xl\:lm-pb-10 {
    padding-bottom: 2.5rem;
  }

  .xl\:lm-text-mobile-heading-five {
    font-size: 1rem;
  }

  .xl\:lm-stack-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .xl\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0;
  }

  .lm-stack-xs.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .lm-stack-s.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  .lm-stack-m.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.875rem;
    margin-bottom: 0;
  }

  .lm-stack-xm.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .lm-stack-r.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .lm-stack-l.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .lm-stack-l.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.xl\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .lm-stack-xl.xl\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (min-width: 1536px) {
  .\32xl\:lm-flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}

@media (orientation: portrait) and (min-width: 768px) {
  .portrait-md\:lm-mt-64 {
    margin-top: 16rem;
  }
}

@media (orientation: landscape) and (min-width: 1024px) {
  .landscape-lg\:lm-block {
    display: block;
  }

  .landscape-lg\:lm-hidden {
    display: none;
  }
}

@media (min-width: 1440px) {
  .xxl\:lm-ml-18 {
    margin-left: 4.5rem;
  }

  .xxl\:lm-block {
    display: block;
  }

  .xxl\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .xxl\:lm-hidden {
    display: none;
  }

  .xxl\:lm-h-26 {
    height: 6.5rem;
  }

  .xxl\:lm-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (min-width: 0px) and (max-width: 375px) {
  .tiny\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .tiny\:lm-mb-15 {
    margin-bottom: 3.75rem;
  }

  .tiny\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .tiny\:lm-w-24 {
    width: 6rem;
  }

  .tiny\:lm-w-full {
    width: 100%;
  }

  .tiny\:lm-w-40 {
    width: 10rem;
  }

  .tiny\:lm-w-56 {
    width: 14rem;
  }

  .tiny\:lm-w-3\/4 {
    width: 75%;
  }

  .tiny\:lm-max-w-full {
    max-width: 100%;
  }

  .tiny\:lm-max-w-xs {
    max-width: 20rem;
  }

  .tiny\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .tiny\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .tiny\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .tiny\:lm-text-heading-six {
    font-size: 1rem;
  }

  .tiny\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .lm-stack-l.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.tiny\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (min-width: 375px) and (max-width: 639px) {
  .xsm\:lm-col-span-1 {
    grid-column: span 1 / span 1;
  }

  .xsm\:lm-col-span-2 {
    grid-column: span 2 / span 2;
  }

  .xsm\:lm-col-span-3 {
    grid-column: span 3 / span 3;
  }

  .xsm\:lm-col-span-4 {
    grid-column: span 4 / span 4;
  }

  .xsm\:lm-col-span-5 {
    grid-column: span 5 / span 5;
  }

  .xsm\:lm-col-span-6 {
    grid-column: span 6 / span 6;
  }

  .xsm\:lm-col-span-7 {
    grid-column: span 7 / span 7;
  }

  .xsm\:lm-col-span-8 {
    grid-column: span 8 / span 8;
  }

  .xsm\:lm-col-span-9 {
    grid-column: span 9 / span 9;
  }

  .xsm\:lm-col-span-10 {
    grid-column: span 10 / span 10;
  }

  .xsm\:lm-col-span-11 {
    grid-column: span 11 / span 11;
  }

  .xsm\:lm-col-span-12 {
    grid-column: span 12 / span 12;
  }

  .xsm\:lm-col-span-full {
    grid-column: 1 / -1;
  }

  .xsm\:lm-m-0 {
    margin: 0px;
  }

  .xsm\:lm-m-1 {
    margin: 0.25rem;
  }

  .xsm\:lm-m-2 {
    margin: 0.5rem;
  }

  .xsm\:lm-m-3 {
    margin: 0.75rem;
  }

  .xsm\:lm-m-4 {
    margin: 1rem;
  }

  .xsm\:lm-m-5 {
    margin: 1.25rem;
  }

  .xsm\:lm-m-6 {
    margin: 1.5rem;
  }

  .xsm\:lm-m-8 {
    margin: 2rem;
  }

  .xsm\:lm-m-10 {
    margin: 2.5rem;
  }

  .xsm\:lm-mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }

  .xsm\:lm-mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }

  .xsm\:lm-mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }

  .xsm\:lm-mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }

  .xsm\:lm-mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .xsm\:lm-mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }

  .xsm\:lm-mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xsm\:lm-mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .xsm\:lm-mx-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  .xsm\:lm-my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .xsm\:lm-my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .xsm\:lm-my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .xsm\:lm-my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .xsm\:lm-my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .xsm\:lm-my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }

  .xsm\:lm-my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .xsm\:lm-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .xsm\:lm-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .xsm\:lm-ml-0 {
    margin-left: 0px;
  }

  .xsm\:lm-ml-1 {
    margin-left: 0.25rem;
  }

  .xsm\:lm-ml-2 {
    margin-left: 0.5rem;
  }

  .xsm\:lm-ml-3 {
    margin-left: 0.75rem;
  }

  .xsm\:lm-ml-4 {
    margin-left: 1rem;
  }

  .xsm\:lm-ml-5 {
    margin-left: 1.25rem;
  }

  .xsm\:lm-ml-6 {
    margin-left: 1.5rem;
  }

  .xsm\:lm-ml-8 {
    margin-left: 2rem;
  }

  .xsm\:lm-ml-10 {
    margin-left: 2.5rem;
  }

  .xsm\:lm-mr-0 {
    margin-right: 0px;
  }

  .xsm\:lm-mr-1 {
    margin-right: 0.25rem;
  }

  .xsm\:lm-mr-2 {
    margin-right: 0.5rem;
  }

  .xsm\:lm-mr-3 {
    margin-right: 0.75rem;
  }

  .xsm\:lm-mr-4 {
    margin-right: 1rem;
  }

  .xsm\:lm-mr-5 {
    margin-right: 1.25rem;
  }

  .xsm\:lm-mr-6 {
    margin-right: 1.5rem;
  }

  .xsm\:lm-mr-8 {
    margin-right: 2rem;
  }

  .xsm\:lm-mr-10 {
    margin-right: 2.5rem;
  }

  .xsm\:lm-mt-0 {
    margin-top: 0px;
  }

  .xsm\:lm-mt-1 {
    margin-top: 0.25rem;
  }

  .xsm\:lm-mt-2 {
    margin-top: 0.5rem;
  }

  .xsm\:lm-mt-3 {
    margin-top: 0.75rem;
  }

  .xsm\:lm-mt-4 {
    margin-top: 1rem;
  }

  .xsm\:lm-mt-5 {
    margin-top: 1.25rem;
  }

  .xsm\:lm-mt-6 {
    margin-top: 1.5rem;
  }

  .xsm\:lm-mt-8 {
    margin-top: 2rem;
  }

  .xsm\:lm-mt-10 {
    margin-top: 2.5rem;
  }

  .xsm\:lm-mb-0 {
    margin-bottom: 0px;
  }

  .xsm\:lm-mb-1 {
    margin-bottom: 0.25rem;
  }

  .xsm\:lm-mb-2 {
    margin-bottom: 0.5rem;
  }

  .xsm\:lm-mb-3 {
    margin-bottom: 0.75rem;
  }

  .xsm\:lm-mb-4 {
    margin-bottom: 1rem;
  }

  .xsm\:lm-mb-5 {
    margin-bottom: 1.25rem;
  }

  .xsm\:lm-mb-6 {
    margin-bottom: 1.5rem;
  }

  .xsm\:lm-mb-8 {
    margin-bottom: 2rem;
  }

  .xsm\:lm-mb-10 {
    margin-bottom: 2.5rem;
  }

  .xsm\:lm-mb-15 {
    margin-bottom: 3.75rem;
  }

  .xsm\:lm-block {
    display: block;
  }

  .xsm\:lm-inline-block {
    display: inline-block;
  }

  .xsm\:lm-inline {
    display: inline;
  }

  .xsm\:lm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .xsm\:lm-grid {
    display: grid;
  }

  .xsm\:lm-hidden {
    display: none;
  }

  .xsm\:lm-items-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }

  .xsm\:lm-items-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
  }

  .xsm\:lm-items-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .xsm\:lm-items-baseline {
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }

  .xsm\:lm-items-stretch {
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
  }

  .xsm\:lm-justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }

  .xsm\:lm-justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }

  .xsm\:lm-justify-center {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  .xsm\:lm-justify-between {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  .xsm\:lm-justify-around {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }

  .xsm\:lm-justify-evenly {
    -webkit-box-pack: space-evenly;
        -ms-flex-pack: space-evenly;
            justify-content: space-evenly;
  }

  .xsm\:lm-p-0 {
    padding: 0px;
  }

  .xsm\:lm-p-1 {
    padding: 0.25rem;
  }

  .xsm\:lm-p-2 {
    padding: 0.5rem;
  }

  .xsm\:lm-p-3 {
    padding: 0.75rem;
  }

  .xsm\:lm-p-4 {
    padding: 1rem;
  }

  .xsm\:lm-p-5 {
    padding: 1.25rem;
  }

  .xsm\:lm-p-6 {
    padding: 1.5rem;
  }

  .xsm\:lm-p-8 {
    padding: 2rem;
  }

  .xsm\:lm-p-10 {
    padding: 2.5rem;
  }

  .xsm\:lm-px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .xsm\:lm-px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }

  .xsm\:lm-px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }

  .xsm\:lm-px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  .xsm\:lm-px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .xsm\:lm-px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xsm\:lm-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xsm\:lm-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xsm\:lm-px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .xsm\:lm-py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .xsm\:lm-py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .xsm\:lm-py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .xsm\:lm-py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }

  .xsm\:lm-py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }

  .xsm\:lm-py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }

  .xsm\:lm-py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .xsm\:lm-py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xsm\:lm-py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }

  .xsm\:lm-pl-0 {
    padding-left: 0px;
  }

  .xsm\:lm-pl-1 {
    padding-left: 0.25rem;
  }

  .xsm\:lm-pl-2 {
    padding-left: 0.5rem;
  }

  .xsm\:lm-pl-3 {
    padding-left: 0.75rem;
  }

  .xsm\:lm-pl-4 {
    padding-left: 1rem;
  }

  .xsm\:lm-pl-5 {
    padding-left: 1.25rem;
  }

  .xsm\:lm-pl-6 {
    padding-left: 1.5rem;
  }

  .xsm\:lm-pl-8 {
    padding-left: 2rem;
  }

  .xsm\:lm-pl-10 {
    padding-left: 2.5rem;
  }

  .xsm\:lm-pr-0 {
    padding-right: 0px;
  }

  .xsm\:lm-pr-1 {
    padding-right: 0.25rem;
  }

  .xsm\:lm-pr-2 {
    padding-right: 0.5rem;
  }

  .xsm\:lm-pr-3 {
    padding-right: 0.75rem;
  }

  .xsm\:lm-pr-4 {
    padding-right: 1rem;
  }

  .xsm\:lm-pr-5 {
    padding-right: 1.25rem;
  }

  .xsm\:lm-pr-6 {
    padding-right: 1.5rem;
  }

  .xsm\:lm-pr-8 {
    padding-right: 2rem;
  }

  .xsm\:lm-pr-10 {
    padding-right: 2.5rem;
  }

  .xsm\:lm-pt-0 {
    padding-top: 0px;
  }

  .xsm\:lm-pt-1 {
    padding-top: 0.25rem;
  }

  .xsm\:lm-pt-2 {
    padding-top: 0.5rem;
  }

  .xsm\:lm-pt-3 {
    padding-top: 0.75rem;
  }

  .xsm\:lm-pt-4 {
    padding-top: 1rem;
  }

  .xsm\:lm-pt-5 {
    padding-top: 1.25rem;
  }

  .xsm\:lm-pt-6 {
    padding-top: 1.5rem;
  }

  .xsm\:lm-pt-8 {
    padding-top: 2rem;
  }

  .xsm\:lm-pt-10 {
    padding-top: 2.5rem;
  }

  .xsm\:lm-pb-0 {
    padding-bottom: 0px;
  }

  .xsm\:lm-pb-1 {
    padding-bottom: 0.25rem;
  }

  .xsm\:lm-pb-2 {
    padding-bottom: 0.5rem;
  }

  .xsm\:lm-pb-3 {
    padding-bottom: 0.75rem;
  }

  .xsm\:lm-pb-4 {
    padding-bottom: 1rem;
  }

  .xsm\:lm-pb-5 {
    padding-bottom: 1.25rem;
  }

  .xsm\:lm-pb-6 {
    padding-bottom: 1.5rem;
  }

  .xsm\:lm-pb-8 {
    padding-bottom: 2rem;
  }

  .xsm\:lm-pb-10 {
    padding-bottom: 2.5rem;
  }

  .xsm\:lm-text-heading-six {
    font-size: 1rem;
  }

  .xsm\:lm-stack-horizontal {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  .xsm\:lm-stack-vertical {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }

  .lm-stack-xs.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.25rem;
    margin-bottom: 0;
  }

  .lm-stack-xs.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.25rem;
    margin-right: 0;
  }

  .lm-stack-s.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.5rem;
    margin-bottom: 0;
  }

  .lm-stack-s.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.5rem;
    margin-right: 0;
  }

  .lm-stack-m.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.75rem;
    margin-bottom: 0;
  }

  .lm-stack-m.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.75rem;
    margin-right: 0;
  }

  .lm-stack-xm.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 0.875rem;
    margin-bottom: 0;
  }

  .lm-stack-xm.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 0.875rem;
    margin-right: 0;
  }

  .lm-stack-r.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 0;
  }

  .lm-stack-r.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1rem;
    margin-right: 0;
  }

  .lm-stack-l.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 1.5rem;
    margin-bottom: 0;
  }

  .lm-stack-l.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 1.5rem;
    margin-right: 0;
  }

  .lm-stack-xl.xsm\:lm-stack-horizontal > *:not(:last-child) {
    margin-right: 2rem;
    margin-bottom: 0;
  }

  .lm-stack-xl.xsm\:lm-stack-vertical > *:not(:last-child) {
    margin-bottom: 2rem;
    margin-right: 0;
  }
}

@media (orientation: portrait) {
  .portrait\:lm-pt-16 {
    padding-top: 4rem;
  }
}

.\[\&\:nth-child\(2\)\]\:lm-opacity-40:nth-child(2) {
  opacity: 0.4;
}

@media (orientation: landscape) {
  .landscape\:lm-block {
    display: block;
  }

  .landscape\:lm-hidden {
    display: none;
  }

  .landscape\:lm-pt-8 {
    padding-top: 2rem;
  }
}

.\[\&\:nth-child\(3\)\]\:lm-opacity-60:nth-child(3) {
  opacity: 0.6;
}

.\[\&\:nth-child\(4\)\]\:lm-opacity-80:nth-child(4) {
  opacity: 0.8;
}

.\[\&\>\*\:nth-child\(even\)\]\:lm-bg-opacity-50>*:nth-child(even) {
  --tw-bg-opacity: 0.5;
}

.\[\&_label\]\:lm-mb-2 label {
  margin-bottom: 0.5rem;
}

.\[\&_label\]\:lm-text-base label {
  font-size: 1rem;
  line-height: 1.5rem;
}
